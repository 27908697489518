import React from 'react';
import { UseFormSetValue } from 'react-hook-form';
import useGetAppPermission from '../../hooks/useGetAppPermission';
import { RolesProvider } from './RolesForm';
import { RolesPanel } from './RolesPanel';

type Props = {
  permission: string;

  name: string;
  setValue: UseFormSetValue<any>;
};
export const RolesCollapse = (props: Props) => {
  const { permission, name } = props;
  const { dataPermissions, dataArray } = useGetAppPermission(permission, name);

  return (
    <RolesProvider {...dataPermissions}>
      <div>
        {dataArray.map((key) => {
          return (
            <>
              <RolesPanel key={key} keyData={key} />
            </>
          );
        })}
      </div>
    </RolesProvider>
  );
};
