import { gql } from '@apollo/client';
import { CenterData } from './center';

export const GET_CENTER_ITEMS = gql`
query Centre_getList($query: QueryCentreInput) {
  Centre_getList(query: $query) {
    data {
      ${CenterData}
    }
    count
    page
    pages
    limit
  }
}
`;

export const CREATE_CENTER = gql`
mutation Centre_create($payload: CreateCentreInput!) {
  Centre_create(createCentreInput: $payload) {
    ${CenterData}
  }
}
`;

export const UPDATE_CENTER = gql`
mutation Centre_update($payload: UpdateCentreInput!) {
  Centre_update(updateCentreInput: $payload) {
    ${CenterData}
  }
}
`;

export const DETAIL_CENTER = gql`
query Centre_getById($id: String!) {
  Centre_getById(id: $id) {
    ${CenterData}
  }
}
`;

export const GET_COUNTRY = gql`
  query Countries_getList($query: QueryCountriesInput) {
    Countries_getList(query: $query) {
      data {
        id
        code
        name
      }
    }
  }
`;

export const GET_CITY = gql`
  query Cities_getList($query: QueryCitiesInput) {
    Cities_getList(query: $query) {
      data {
        id
        code
        name
      }
    }
  }
`;

export const GET_DISTRICT = gql`
  query Districts_getList($query: QueryDistrictsInput) {
    Districts_getList(query: $query) {
      data {
        id
        code
        name
      }
    }
  }
`;

export const GET_WARD = gql`
  query Wards_getList($query: QueryWardsInput) {
    Wards_getList(query: $query) {
      data {
        id
        code
        name
      }
    }
  }
`;

export const CHECK_EXIST_BY_SHORT_NAME = gql`
query Center_checkExistByShortName($shortName: String!) {
  Center_checkExistByShortName(name: $shortName) {
    ${CenterData}
  }
}
`;
