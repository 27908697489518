import { gql } from '@apollo/client';
import { PermissionsData } from './permission_data';

export const GET_PERMISSION_ALLS = gql`
  query Permission_getAll {
    Permission_getAll {
      ${PermissionsData}
    }
  }
`;
