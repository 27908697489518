export const MenuItemData = `
  id
  name
  parent{id name}
  isActive
  displayItem
  icon
  url
  description
  reportUrl
  level
`;
