import { CityEntity, ListCity } from '@/domains/city/entities';
import { CityPayloadForm, QueryCityPayload } from '@/domains/city/ports/payloads';
import { CityRepository } from '@/domains/city/ports/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import {
  CHECK_EXISTED_CITY,
  CREATE_CITY,
  GET_ALL_CITY,
  GET_DETAIL_CITY,
  GET_LIST_CITY,
  UPDATE_CITY,
} from '../../graphql';

export class CityHttpRepository implements CityRepository {
  async createCity(payload: CityPayloadForm): Promise<CityEntity> {
    const response: CityEntity = await HttpClientAdapter.mutate<CityEntity>({
      mutation: CREATE_CITY,
      variables: { payload: { ...payload } },
    });
    return response;
  }

  async updateCity(payload: CityPayloadForm): Promise<CityEntity> {
    const response: CityEntity = await HttpClientAdapter.mutate<CityEntity>({
      mutation: UPDATE_CITY,
      variables: { payload: { ...payload } },
    });
    return response;
  }

  async getListCity(payload?: QueryCityPayload): Promise<ListCity> {
    const response: ListCity = await HttpClientAdapter.query<ListCity>({
      query: GET_LIST_CITY,
      dataKey: 'Cities_getList',
      variables: {
        query: { limit: 10, page: 1, sortBy: ['lastModifiedAt|desc', '_id|desc'], ...payload },
      },
    });
    return response;
  }

  async getDetailCity(id: string): Promise<CityEntity> {
    const response: CityEntity = await HttpClientAdapter.query<CityEntity>({
      query: GET_DETAIL_CITY,
      dataKey: 'City_getById',
      variables: { id },
    });
    return response;
  }

  async getAllCity(payload?: QueryCityPayload | undefined): Promise<CityEntity[]> {
    const response: CityEntity[] = await HttpClientAdapter.query<CityEntity[]>({
      query: GET_ALL_CITY,
      dataKey: 'City_getAll',
      variables: { payload: { ...payload } },
    });
    console.log('response :>> ', response);
    return response;
  }

  async checkExistCityByCode(code: string, aborterRef: any): Promise<CityEntity | null> {
    try {
      const response: CityEntity = await HttpClientAdapter.query<CityEntity>({
        query: CHECK_EXISTED_CITY,
        variables: { payload: { code } },
        fetchPolicy: 'network-only',
        context: {
          fetchOptions: {
            signal: aborterRef.current.signal,
          },
        },
        dataKey: 'City_getByCriteria',
      });
      return response;
    } catch (error) {
      return null;
    }
  }
}
