// Generate code from clean architecture template

import { ListCountry } from '../entities';
import { QueryCountriesPayload } from '../ports/payloads';
import { CountriesRepository } from '../ports/repositories';

export class GetListCountriesUsecase {
  constructor(private readonly repo: CountriesRepository) {}

  async run(payload?: QueryCountriesPayload): Promise<ListCountry> {
    return this.repo.getListCountry(payload);
  }
}
