import { useBaseViewModel } from '@/common';
import { AccountsEntity } from '@/domain/entities/account';
import { FindAccountPayload } from '@/domain/ports/account/payloads';
import { AccountsListUseCase } from '@/domain/use-cases/accounts';
import { useState } from 'react';
import { AccountRepoIml } from '../adapters/repositories';

export default function GetListAccount() {
  const getListAccount = new AccountsListUseCase(new AccountRepoIml());

  const { loading, error, catchAction } = useBaseViewModel();
  const [pageData, setPageData] = useState<{ dataList: AccountsEntity[] }>({ dataList: [] });

  const actionGetAll = async (payload?: FindAccountPayload) => {
    await catchAction(async () => {
      const { data } = await getListAccount.run(payload);
      setPageData({ dataList: data });
    });
  };

  return {
    loading,
    error,
    pageData,
    actionGetAll,
  };
}
