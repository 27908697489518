import CoreDrawer from '@/components/CoreDrawer';
import { DetailProps } from '@/domain/entities/detail';
import GetListPermission from '@/modules/permission/viewmodels/getAll';

import { Spin, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useViewModel from '../../viewmodels/detail';
import { RolesForm } from './RolesForm';

export const Detail: React.FC<DetailProps> = (props: DetailProps): JSX.Element => {
  const { open, handleClose, setOpen, setRefresh } = props;
  const { loadingDetailRoles, errorDetailRoles, detailRoles, actionGetDetail } = useViewModel();
  const [currentQueryParameters] = useSearchParams();
  const showId = currentQueryParameters.get('id');
  const getAction = currentQueryParameters.get('action');
  const { listPermission, actionGetListPermission, loadingListPermission, errorListPermission } =
    GetListPermission();

  useEffect(() => {
    if (
      (showId !== null && getAction === 'Detail') ||
      (showId === null && getAction === 'Create')
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [open, handleClose, setOpen]);

  useEffect(() => {
    if (showId) {
      actionGetDetail();
    }
  }, [open]);
  useEffect(() => {
    actionGetListPermission();
  }, [open]);

  const rolesFormShown =
    !errorDetailRoles &&
    !loadingDetailRoles &&
    detailRoles.data.id !== '' &&
    !errorListPermission &&
    !loadingListPermission &&
    listPermission.Permission_getAll.length > 0;

  const rolesFormShownWithoutId =
    !errorListPermission &&
    !loadingListPermission &&
    Array.isArray(listPermission.Permission_getAll) &&
    listPermission.Permission_getAll.length > 0;

  const renderContent = () => {
    if (showId) {
      return (
        <>
          <div>{loadingDetailRoles && loadingListPermission && <Spin />}</div>
          {errorDetailRoles && errorListPermission && <div>Error</div>}
          {rolesFormShown && (
            <RolesForm
              detailRole={detailRoles.data}
              openForm={open}
              showId={showId}
              listPermission={listPermission.Permission_getAll}
              setRefresh={setRefresh}
            />
          )}
        </>
      );
    }
    return (
      <>
        <div>{loadingListPermission && <Spin />}</div>
        {errorListPermission && <div>Error</div>}
        {rolesFormShownWithoutId && (
          <RolesForm
            detailRole={detailRoles.data}
            openForm={open}
            showId={showId}
            listPermission={listPermission.Permission_getAll}
            setRefresh={setRefresh}
          />
        )}
      </>
    );
  };

  return (
    <CoreDrawer
      open={open}
      onClose={() => handleClose()}
      title={
        <div>
          <Typography.Title level={5}>Roles</Typography.Title>
        </div>
      }
      content={renderContent()}
    />
  );
};
