// Generate code from clean architecture template

import { FindResult, useBaseViewModel } from '@/common';
import { EmployeeHttpRepository } from '../adapters/repositories';
import { ImportEmployeePayload, QueryEmployeePayload } from '@domains/employee/ports/payloads';
import { useState } from 'react';
import { EmployeeEntity } from '@/domains/employee/entities';
import { FindAllEmployeeUsecase } from '@/domains/employee/usecases/find-all.usecase';
import { ImportEmployeeUsecase } from '@/domains/employee/usecases/import.usecase';
import { notification } from 'antd';

export default function EmployeeViewModel() {
  const [dataListEmployee, setDataListEmployee] = useState<FindResult<EmployeeEntity>>();
  const { loading, error, catchAction } = useBaseViewModel();

  const findAllUC = new FindAllEmployeeUsecase(new EmployeeHttpRepository());
  const importUC = new ImportEmployeeUsecase(new EmployeeHttpRepository());

  const actionGetEmployee = async (payload: QueryEmployeePayload) => {
    catchAction(async () => {
      const data = await findAllUC.run(payload);
      setDataListEmployee(data);
    });
  };
  const actionImportEmployee = async (params: ImportEmployeePayload) => {
    try {
      await importUC.run(params);
      notification.success({ message: 'Upload employee success', placement: 'bottomRight' });
    } catch (error) {
      notification.error({ message: 'Upload employee error', placement: 'bottomRight' });
    }
  };

  return {
    actionGetEmployee,
    dataListEmployee,
    loading,
    error,
    actionImportEmployee,
  };
}
