import React from 'react';
import CoreTable from '@/components/Table';
import { useCoreContext } from '@/core';
import { CountryTableColumns } from '../../components/TableColumn';
import { get } from 'lodash';
import { Typography } from 'antd';
import { CorePagination } from '@/components/Pagination';

export const CountryTable = () => {
  const { loading, countryData, setValue, getValues } = useCoreContext();
  const tableColumns = CountryTableColumns();

  return (
    <div>
      <CoreTable
        columns={tableColumns}
        dataSource={get(countryData, 'data', [])}
        tableName='Center'
        loading={loading}
      />
      <div className='flex mt-4 justify-between'>
        <Typography.Title level={3}>
          Total: {get(countryData, 'count') > 999 ? '>= 1000' : get(countryData, 'count')}
        </Typography.Title>
        <CorePagination
          total={get(countryData, 'count') ?? 0}
          defaultPageSize={10}
          showSizeChanger
          pageSizeOptions={['5', '10', '20']}
          onChange={(page, pageSize) => {
            setValue('page', page);
            setValue('limit', pageSize);
          }}
          current={getValues('page')}
        />
      </div>
    </div>
  );
};
