import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import _ from 'lodash';
import { RolesContext } from './RolesForm';
import useGetOnePermission from '../../hooks/useGetOnePermission';

type Props = {
  list: string[];
  nameApp: string;
  keyData: string;
  isSelected: (value: string) => boolean;
  toggle: (value: string) => void;
  dataSelected?: any[];
};
export const RolesCheckBoxForm = (props: Props) => {
  const { list, nameApp, keyData, toggle } = props;
  const { listItemChecked, setListItemChecked } = useContext(RolesContext);
  const { isChecked, handleClick } = useGetOnePermission(
    listItemChecked,
    nameApp,
    setListItemChecked,
  );

  return (
    <>
      <Row>
        {list.map((item: string, index) => {
          const permissionDisplayValue = _.capitalize(_.startCase(item.replace(keyData, '')));
          return (
            <Col span={6} key={index}>
              <label className='flex items-center'>
                <input
                  className='mr-2 h-4 w-4'
                  onClick={() => {
                    toggle(item);
                    handleClick(item);
                  }}
                  checked={isChecked(item)}
                  type='checkbox'
                />
                {permissionDisplayValue}
              </label>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
