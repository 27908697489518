import { PermissionRepository } from '@/domain/ports/permission/repositories';

export class AllPermissionUseCase {
  constructor(private readonly repository: PermissionRepository) {}

  async run() {
    const data = await this.repository.getAll();
    return data;
  }
}
