/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { EmployeeEntity } from '@/domains/employee/entities';
import { ColumnsType } from 'antd/lib/table';
import { Tag } from 'antd';
import { get } from 'lodash';
import dayjs from 'dayjs';

const ImportEmployeeTableColumns = () => {
  const status = {
    DONE: 'green',
    PREPARING: 'orange',
  };

  const tableColumns: ColumnsType<EmployeeEntity> = [
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_: any, record) => (
        <Tag color={`${status[record.status as unknown as keyof typeof status]}`}>
          {record?.status}
        </Tag>
      ),
    },
    {
      title: 'Success count',
      dataIndex: 'successCount',
      key: 'successCount',
    },
    {
      title: 'Failure count',
      dataIndex: 'failureCount',
      key: 'failureCount',
    },

    {
      title: 'Created at',
      render: (_: any, record: any) => {
        return <div>{dayjs(Number(get(record, 'createdAt'))).format('DD/MM/YYYY')}</div>;
      },
    },
  ];
  return tableColumns;
};

export default ImportEmployeeTableColumns;
