import { Authorize } from '@/core';
import React from 'react';
import { EmployeeProvider } from '../context';
import EmployeeTable from './List/EmployeeTable';
import EmployeeTableFilter from './List/EmployeeTableFilter';
import { DrawEmployee } from '../components';
import { DrawImportEmployee } from './Import/DrawImportEmployee';

export const EmployeeScreen = () => {
  return (
    <EmployeeProvider>
      <EmployeeTableFilter />
      <EmployeeTable />
      <DrawEmployee />
      <DrawImportEmployee />
    </EmployeeProvider>
  );
};

export const EmployeeList = Authorize<unknown>(EmployeeScreen);
