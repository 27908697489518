import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { Layout } from './components';
import { Sidebar } from './components/Sidebar';
import { config } from './config';
import { useDocumentTitle } from './core';
import './index.css';
import { AccountList } from './modules/accounts/UI';
import { DropdownMenu } from './modules/layouts/DropdownMenu';
import { useGetTitle } from './modules/layouts/sidebar/hooks/useGetTitle';
// import { MenuItemList } from './modules/menu-items/UI/List';
import { CenterList } from './modules/center/screens';
import { CityList } from './modules/city/screens';
import { CountryList } from './modules/countries/screens';
import { DistrictList } from './modules/district/screens';
import { EmployeeList } from './modules/employee/screens';
import { RolesList } from './modules/roles/UI';
import { WardList } from './modules/wards/screens';
import { AppProvider } from './core/context/AppContext';
const { Header, Content, Footer } = Layout;

const App: React.FC<unknown> = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { title, setTitle, hanldeTittle } = useGetTitle();
  const location = useLocation();
  const pathName = location.pathname;
  const path = pathName.slice(1);

  useDocumentTitle();
  useEffect(() => {
    hanldeTittle(path);
  }, [pathName]);
  return (
    <AppProvider>
      <Layout style={{ minHeight: '100vh' }}>
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} setTitle={setTitle} />
        <Layout className='site-layout'>
          <Header className='site-layout-background px-3 flex items-center'>
            {React.createElement(collapsed ? RightOutlined : LeftOutlined, {
              onClick: () => setCollapsed(!collapsed),
            })}
            <Typography.Title className='ml-2 mt-2' level={4}>
              {title}
            </Typography.Title>
            <DropdownMenu />
          </Header>
          <Content style={{ margin: '24px 16px', height: '100%' }}>
            <Routes>
              {/* <Route path='/menu-items' element={<MenuItemList />} /> */}
              <Route path='*' element={<span></span>} />
              <Route path='/accounts' element={<AccountList />} />
              <Route path='/roles' element={<RolesList />} />
              <Route path='/center' element={<CenterList />} />
              <Route path='/countries' element={<CountryList />} />
              <Route path='/cities' element={<CityList />} />
              <Route path='/districts' element={<DistrictList />} />
              <Route path='/wards' element={<WardList />} />
              <Route path='/employees' element={<EmployeeList />} />
            </Routes>
          </Content>
          <Footer style={{ textAlign: 'center' }}>{config.footer.text}</Footer>
        </Layout>
      </Layout>
    </AppProvider>
  );
};

export default App;
