import { getErrorMessage } from '@/components/hooks/useGetErrorMessage';
import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { useCoreContext } from '@/core';
import { PropsCenterForm } from '@/domains/center/entities';
import { CenterPayloadForm } from '@/domains/center/ports/payloads';
import { CreateCenterUsecase } from '@/domains/center/usecases';
import { UpdateCenterUsecase } from '@/domains/center/usecases/update.usecase';
import { notification } from 'antd';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { CenterHttpRepository } from '../adapters/repositories';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateCenter } from '../validations';

const useCenterForm = (props: PropsCenterForm) => {
  const { detailCenter } = props;
  const { actionGetAllCenter, getValues, reset } = useCoreContext();
  const { handleClose } = useHandleActionItem();
  const [currentQueryParameters] = useSearchParams();
  const showId = currentQueryParameters.get('id');
  const createCenter = new CreateCenterUsecase(new CenterHttpRepository());
  const updateCenter = new UpdateCenterUsecase(new CenterHttpRepository());
  const { schemaCenter } = validateCenter(props);
  const initialValue = {
    id: '',
    name: '',
    shortName: '',
    hotLine: '',
    email: '',
    // status: Status.Active,
    isActive: true,
    address: {
      countryCode: 'VN',
      cityCode: '',
      districtCode: '',
      wardCode: '',
      address: '',
    },
    coordinate: '',
    businessUnits: [],
  };

  const formatBusinessUnit = () => {
    if (Array.isArray(detailCenter.businessUnits) && detailCenter.businessUnits.length > 0) {
      return detailCenter.businessUnits;
    }
    return [];
  };

  const methodForm = useForm({
    mode: 'onTouched',
    defaultValues: {
      id: detailCenter.id ?? '',
      name: detailCenter.name ?? '',
      shortName: detailCenter.shortName ?? '',
      hotLine: detailCenter.hotLine ?? '',
      email: detailCenter.email ?? '',
      isActive: detailCenter.isActive ?? true,
      address: {
        countryCode: detailCenter.address.countryCode ?? '',
        cityCode: detailCenter.address.cityCode ?? '',
        districtCode: detailCenter.address.districtCode ?? '',
        wardCode: detailCenter.address.wardCode ?? '',
        address: detailCenter.address.address ?? '',
      },
      coordinate: detailCenter.coordinate ?? '',
      businessUnits: formatBusinessUnit(),
    },
    resolver: yupResolver(schemaCenter),
  });

  const onSubmit = methodForm.handleSubmit(async (data: CenterPayloadForm) => {
    // const formatStatus = data.status === false ? Status.InActive : Status.Active;
    data = {
      ...data,
    };

    try {
      if (!showId) {
        delete data.id;
        await createCenter.run(data);
        notification.success({
          message: 'You already have successfully created lead status transition',
          placement: 'bottomRight',
        });
      } else {
        await updateCenter.run(data);
        notification.success({
          message: 'You already have successfully updated lead status transition',
          placement: 'bottomRight',
        });
      }
      handleClose();
      methodForm.reset({
        ...initialValue,
      });

      reset();
      actionGetAllCenter(getValues());
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getErrorMessage(error),
        placement: 'bottomRight',
      });
    }
  });

  return {
    methodForm,
    onSubmit,
    initialValue,
  };
};

export default useCenterForm;
