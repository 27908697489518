import CoreDrawer from '@/components/CoreDrawer';
import UploadAttachment from '@/components/UploadAttachments';
import { useCoreContext } from '@/core';
import { Button, Divider, notification, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ImportTable from './ImportTable';
import Title from 'antd/lib/typography/Title';
import { DownloadOutlined, DownOutlined } from '@ant-design/icons';
import { config } from '@/config';
import { useGoogleAnalytics } from '@mx/metric/frontend';

export const DrawImportEmployee: React.FC = () => {
  const {
    open,
    handleClose,
    setOpen,
    // actionGetDetailEmployee,
    dataImportEmployee,
    loading,
    error,
    actionGetListImportEmployee,
    actionImportEmployee,
  } = useCoreContext();
  const [searchParams] = useSearchParams();
  const { gaEvent } = useGoogleAnalytics();
  const type = searchParams.get('action');

  // useEffect(() => {
  //   if (id) {
  //     actionGetDetailEmployee(id);
  //   }
  // }, [id]);

  useEffect(() => {
    if (type === 'Create') {
      actionGetListImportEmployee();

      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [type]);
  const handleImport = async (response: string) => {
    try {
      await actionImportEmployee({ linkFile: response });
      notification.success({
        message: 'Import Employee success',
        placement: 'bottomRight',
      });
      gaEvent({
        eventName: 'import_employee',
        eventParams: {
          action: 'upload',
        },
      });
    } catch (error) {
      notification.error({
        message: 'Import Employee failed',
        placement: 'bottomRight',
      });
    }
  };
  const handleExportSample = () => {
    const fileUrl =
      config.base.rootEndpoint + '/uploads/documents/test-single-1722326859888-8210.xlsx';
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('sample.xlsx', 'file.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderContent = () => {
    return (
      <>
        <Title level={4}>Import info</Title>
        <Divider />
        <UploadAttachment
          onFinish={handleImport}
          accept='.xlsx'
          setLoading={() => {
            //
          }}
        />
        <Button
          type='primary'
          icon={<DownloadOutlined />}
          className='ml-2'
          onClick={handleExportSample}
        >
          Download template
        </Button>

        <br />
        <br />
        <ImportTable />
      </>
    );
  };
  return <CoreDrawer open={open} onClose={handleClose} content={renderContent()} />;
};
