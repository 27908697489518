import { useBaseViewModel } from '@/common';
import { CityEntity } from '@/domains/city/entities';
import { FindCityUseCase } from '@/domains/city/usecases/find.usecase';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CityHttpRepository } from '../adapters/repositories';

export default function GetDetailCity() {
  const getDetailCity = new FindCityUseCase(new CityHttpRepository());
  const [currentQueryParameters] = useSearchParams();
  const getId = currentQueryParameters.get('id');

  const { loading: loadingCity, error: errorCity, catchAction } = useBaseViewModel();
  const [detailCity, setDetailCity] = useState<{
    data: CityEntity;
  }>({
    data: {
      id: '',
      name: '',
      code: '',
      country: '',
      isActive: true,
    },
  });

  const actionGetDetail = () => {
    if (getId) {
      catchAction(async () => {
        const dataDetail = await getDetailCity.find(getId);
        setDetailCity({ data: dataDetail });
      });
    }
  };

  return {
    loadingCity,
    errorCity,
    detailCity,
    actionGetDetail,
  };
}
