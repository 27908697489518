import CoreDrawer from '@/components/CoreDrawer';
import { Spin } from 'antd';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import GetDetailDistrict from '../../viewmodels/district-detail.viewmodel';
import { DistrictForm } from './DistrictForm';

interface Props {
  open: boolean;
  handleClose: () => void;
  setOpen: (value: boolean) => void;
}

export const DetailDistrict = (props: Props) => {
  const { open, setOpen, handleClose } = props;
  const { loadingDistrict, errorDistrict, detailDistrict, actionGetDetail } = GetDetailDistrict();

  const [currentQueryParameters] = useSearchParams();
  const showId = currentQueryParameters.get('id');
  const getAction = currentQueryParameters.get('action');

  useEffect(() => {
    if ((showId !== null && getAction === 'Detail') || getAction === 'Create') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [open, handleClose, setOpen]);

  useEffect(() => {
    if (showId) {
      actionGetDetail();
    }
  }, [open]);

  const renderContent = () => {
    return (
      <>
        <div>{loadingDistrict && <Spin />}</div>
        {errorDistrict && <div>Error</div>}
        {!errorDistrict && !loadingDistrict && (
          <DistrictForm detailDistrict={get(detailDistrict, 'data')} openForm={open} />
        )}
      </>
    );
  };

  return (
    <CoreDrawer
      open={open}
      onClose={() => handleClose()}
      content={renderContent()}
      bodyStyle={{ marginTop: '20px' }}
    />
  );
};
