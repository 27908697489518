// Generate code from clean architecture template

import { FindResult } from '@/common';
import { EmployeeEntity, WorkShift } from '@/domains/employee/entities';
import {
  FindWorkShiftPayload,
  ImportEmployeePayload,
  QueryEmployeePayload,
  UpdateEmployeePayload,
} from '@/domains/employee/ports/payloads';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { EmployeeRepository } from '@domains/employee/ports/repositories';
import {
  FIND_BY_ID_EMPLOYEE,
  FIND_WORK_SHIFT,
  GET_EMPLOYEE_LIST,
  IMPORT_EMPLOYEE,
  UPDATE_EMPLOYEE,
} from '../../graphql';

export class EmployeeHttpRepository implements EmployeeRepository {
  async importEmployee(payload: ImportEmployeePayload): Promise<{ id: string }> {
    const response: { id: string } = await HttpClientAdapter.mutate<{ id: string }>({
      mutation: IMPORT_EMPLOYEE,

      variables: {
        input: {
          ...payload,
        },
      },
    });
    return response;
  }
  async findAll(payload: QueryEmployeePayload): Promise<FindResult<EmployeeEntity>> {
    const response: FindResult<EmployeeEntity> = await HttpClientAdapter.query<
      FindResult<EmployeeEntity>
    >({
      query: GET_EMPLOYEE_LIST,
      dataKey: 'Employee_find',
      variables: {
        query: {
          textSearch: payload.textSearch,
          pagination: {
            page: payload.page,
            limit: payload.limit,
          },
        },
      },
    });
    return response;
  }
  findById(id: string): Promise<EmployeeEntity> {
    return HttpClientAdapter.query<EmployeeEntity>({
      query: FIND_BY_ID_EMPLOYEE,
      dataKey: 'Employee_findById',
      variables: {
        id,
      },
    });
  }

  update(payload: UpdateEmployeePayload): Promise<EmployeeEntity> {
    return HttpClientAdapter.mutate<EmployeeEntity>({
      mutation: UPDATE_EMPLOYEE,
      variables: {
        input: {
          ...payload,
        },
      },
    });
  }

  findWorkShift(payload: FindWorkShiftPayload): Promise<WorkShift[]> {
    return HttpClientAdapter.query<WorkShift[]>({
      query: FIND_WORK_SHIFT,
      dataKey: 'WorkShift_find',
      variables: {
        query: payload,
      },
    });
  }
}
