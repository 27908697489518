// Generate code from clean architecture template

import * as yup from 'yup';
import CheckExistCountryByCode from '../viewmodels/check-exist-by-code.viewmodel';
import { PropsCountryForm } from '../viewmodels/countries-form.viewmodel';

export const validateCountry = (props: PropsCountryForm) => {
  const { validateCode } = CheckExistCountryByCode(props);

  const schemaCountry = yup.object().shape({
    name: yup.string().required('Name is empty').trim(),
    code: yup
      .string()
      .required('Code is empty')
      .trim()
      .test('Code exist', 'Code has already existed', validateCode),
  });

  return { schemaCountry };
};
