import { PermissionEntity } from '@/domain/entities/permission';
import { PermissionRepository } from '@/domain/ports/permission/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { GET_PERMISSION_ALLS } from '../../graphql';

export class PermissionRepoIml implements PermissionRepository {
  async getAll(): Promise<{ Permission_getAll: PermissionEntity[] }> {
    const response: { Permission_getAll: PermissionEntity[] } = await HttpClientAdapter.query<{
      Permission_getAll: PermissionEntity[];
    }>({
      query: GET_PERMISSION_ALLS,
    });
    return response;
  }
}
