import { gql } from '@apollo/client';
import { CountryData } from './country';

export * from './country';

export const GET_LIST_COUNTRY = gql`
query Countries_getList($query: QueryCountriesInput) {
  Countries_getList(query: $query) {
    data {
      ${CountryData}
    }
    limit
    count
    page
  }
}
`;

export const CREATE_COUNTRY = gql`
mutation Country_create($payload: CreateCountryInput!) {
  Country_create(createCountryInput: $payload) {
    ${CountryData}
  }
}
`;

export const UPDATE_COUNTRY = gql`
mutation Country_update($payload: UpdateCountryInput!) {
  Country_update(updateCountryInput: $payload) {
    ${CountryData}
  }
}
`;

export const GET_DETAIL_COUNTRY = gql`
query Country_getById($id: String!) {
  Country_getById(id: $id) {
    ${CountryData}
  }
}
`;

export const CHECK_EXISTED_COUNTRY = gql`
query Country_getByCriteria($payload: QueryCountryCriteria) {
  Country_getByCriteria(query: $payload) {
    ${CountryData}
  }
}
`;
