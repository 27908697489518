import { RolesEntity } from '@/domain/entities/roles';
import { RolesRepository } from '@/domain/ports/roles/repositories';

export class DeleteRoleUseCase {
  constructor(private readonly repository: RolesRepository) {}

  async delete(payload: string): Promise<{ dataDeleted: RolesEntity }> {
    const { dataDeleted } = await this.repository.delete(payload);

    return { dataDeleted };
  }
}
