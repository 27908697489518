// Generate code from clean architecture template

import { ListDistrict } from '@/domains/center/entities';
import { DistrictEntity } from '@/domains/district/entities';
import {
  DistrictPayloadForm,
  QueryDistrictGetAllPayload,
  QueryDistrictPayload,
} from '@/domains/district/ports/payloads';
import { DistrictRepository } from '@/domains/district/ports/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import {
  CHECK_EXISTED_DISTRICT,
  CREATE_DISTRICT,
  GET_ALL_LIST_DISTRICT,
  GET_DETAIL_DISTRICT,
  GET_LIST_DISTRICT,
  UPDATE_DISTRICT,
} from '../../graphql';

export class DistrictHttpRepository implements DistrictRepository {
  async createDistrict(payload: DistrictPayloadForm): Promise<DistrictEntity> {
    const response: DistrictEntity = await HttpClientAdapter.mutate<DistrictEntity>({
      mutation: CREATE_DISTRICT,
      variables: { payload: { ...payload } },
    });
    return response;
  }

  async updateDistrict(payload: DistrictPayloadForm): Promise<DistrictEntity> {
    const response: DistrictEntity = await HttpClientAdapter.mutate<DistrictEntity>({
      mutation: UPDATE_DISTRICT,
      variables: { payload: { ...payload } },
    });
    return response;
  }

  async getListDistrict(payload?: QueryDistrictPayload): Promise<ListDistrict> {
    const response: ListDistrict = await HttpClientAdapter.query<ListDistrict>({
      query: GET_LIST_DISTRICT,
      dataKey: 'Districts_getList',
      variables: {
        query: { limit: 10, page: 1, sortBy: ['lastModifiedAt|desc', '_id|desc'], ...payload },
      },
    });
    return response;
  }

  async getDetailDistrict(id: string): Promise<DistrictEntity> {
    const response: DistrictEntity = await HttpClientAdapter.query<DistrictEntity>({
      query: GET_DETAIL_DISTRICT,
      dataKey: 'District_getById',
      variables: { id },
    });
    return response;
  }

  async checkExistDistrictByCode(code: string, aborterRef: any): Promise<DistrictEntity | null> {
    try {
      const response: DistrictEntity = await HttpClientAdapter.query<DistrictEntity>({
        query: CHECK_EXISTED_DISTRICT,
        variables: { payload: { code } },
        fetchPolicy: 'network-only',
        context: {
          fetchOptions: {
            signal: aborterRef.current.signal,
          },
        },
        dataKey: 'District_getByCriteria',
      });
      return response;
    } catch (error) {
      return null;
    }
  }

  async find(query?: QueryDistrictGetAllPayload): Promise<DistrictEntity[]> {
    const response: DistrictEntity[] = await HttpClientAdapter.query<DistrictEntity[]>({
      query: GET_ALL_LIST_DISTRICT,
      dataKey: 'Districts_getAll',
      variables: { query: { sortBy: ['lastModifiedAt|desc', '_id|desc'], ...query } },
    });

    return response;
  }
}
