// Generate code from clean architecture template

import { CenterEntity, CenterTable } from '@/domains/center/entities';
import { CenterPayloadForm, QueryCenterPayload } from '@/domains/center/ports/payloads';
import { CenterRepository } from '@/domains/center/ports/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import {
  CHECK_EXIST_BY_SHORT_NAME,
  CREATE_CENTER,
  DETAIL_CENTER,
  GET_CENTER_ITEMS,
  UPDATE_CENTER,
} from '../../graphql';

export class CenterHttpRepository implements CenterRepository {
  async getListCenter(payload?: QueryCenterPayload): Promise<CenterTable[]> {
    const response: CenterTable[] = await HttpClientAdapter.query<CenterTable[]>({
      query: GET_CENTER_ITEMS,
      dataKey: 'Centre_getList',
      variables: { query: { sortBy: ['lastModifiedAt|desc', '_id|desc'], ...payload } },
    });
    return response;
  }

  async createCenter(payload: CenterPayloadForm): Promise<CenterEntity> {
    const response = await HttpClientAdapter.mutate<CenterEntity>({
      mutation: CREATE_CENTER,
      variables: { payload: payload },
    });
    return response;
  }

  async updateCenter(payload: CenterPayloadForm): Promise<CenterEntity> {
    const response: CenterEntity = await HttpClientAdapter.mutate<CenterEntity>({
      mutation: UPDATE_CENTER,
      variables: { payload: payload },
    });
    return response;
  }

  async getDetailCenter(payload: string): Promise<CenterEntity> {
    const response: CenterEntity = await HttpClientAdapter.query<CenterEntity>({
      query: DETAIL_CENTER,
      dataKey: 'Centre_getById',
      variables: { id: payload },
    });
    return response;
  }

  async checkExistByShortName(name: string, abortController: any): Promise<CenterEntity> {
    const response: CenterEntity = await HttpClientAdapter.query<CenterEntity>({
      query: CHECK_EXIST_BY_SHORT_NAME,
      variables: { name: name },
      fetchPolicy: 'network-only',
      context: {
        fetchOptions: {
          signal: abortController.current.signal,
        },
      },
      dataKey: 'Center_checkExistByShortName',
    });
    return response;
  }
}
