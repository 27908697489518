import { getErrorMessage } from '@/components/hooks/useGetErrorMessage';
import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { useCoreContext } from '@/core';
import { AccountsEntity } from '@/domain/entities/account';
import { UpdateAccountsPayload } from '@/domain/ports/account/payloads';
import { UpdateAccountUseCase } from '@/domain/use-cases/accounts';
import yup from '@/yupGlobal';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { get } from 'lodash';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { AccountRepoIml } from '../adapters/repositories';

interface Props {
  detailAccount: AccountsEntity;
}

const useAccountForm = (props: Props) => {
  const { detailAccount } = props;
  const { handleClose } = useHandleActionItem();
  const updateAccount = new UpdateAccountUseCase(new AccountRepoIml());
  const { actionGetAll } = useCoreContext();

  const formatRoles = useCallback(() => {
    if (Array.isArray(detailAccount.roles) && detailAccount.roles.length > 0) {
      return detailAccount.roles.map((item) => item.id);
    }
    return [];
  }, [detailAccount]);

  const formatName = useCallback(() => {
    if (detailAccount) {
      return `${get(detailAccount, 'lastName', '')} ${get(detailAccount, 'firstName', '')}` ?? '';
    }
    return '';
  }, [detailAccount]);

  const defaultValue = {
    id: '',
    name: '',
    email: '',
    roles: [''],
    isActive: true,
  };

  const methodForm = useForm({
    mode: 'onTouched',
    defaultValues: {
      id: detailAccount.id ?? '',
      name: formatName(),
      email: detailAccount.email ?? '',
      roles: formatRoles(),
      isActive: detailAccount.isActive ?? true,
    },
    resolver: yupResolver(
      yup.object({
        name: yup.string().required('Name is required'),
        email: yup.string().required().email('Invalid email'),
      }),
    ),
  });

  const onSubmit = methodForm.handleSubmit(async (data: UpdateAccountsPayload) => {
    delete data.name;
    delete data.email;
    try {
      await updateAccount.update(data);
      notification.success({
        message: 'You already have successfully updated account',
        placement: 'bottomRight',
      });
      handleClose();
      methodForm.reset({
        ...defaultValue,
      });
      actionGetAll();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getErrorMessage(error),
        placement: 'bottomRight',
      });
    }
  });

  return {
    methodForm,
    onSubmit,
    defaultValue,
  };
};

export default useAccountForm;
