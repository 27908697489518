import { AccountsEntity } from '@/domain/entities/account';
import { UpdateAccountsPayload } from '@/domain/ports/account/payloads';
import { AccountsRepository } from '@/domain/ports/account/repositories';

export class UpdateAccountUseCase {
  constructor(private readonly repository: AccountsRepository) {}

  async update(payload: UpdateAccountsPayload): Promise<AccountsEntity> {
    const account = await this.repository.update(payload);

    return account;
  }
}
