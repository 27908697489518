import { CoreInput } from '@/components/Form/InputAntForm';
import { CoreSwitch } from '@/components/Form/Switch';
import { RolesEntity } from '@/domain/entities/roles';
import { Button, Tabs, Typography } from 'antd';
import React from 'react';
import useViewModel from '../../viewmodels/form';
import { RolesCollapse } from './RolesCollapse';
import { FormProvider } from 'react-hook-form';
import { get } from 'lodash';
import { PermissionEntity } from '@/domain/entities/permission';
import useWatchPermission from '../../hooks/useWatchPermission';

interface Props {
  detailRole: RolesEntity;
  setRefresh: (refresh: boolean) => void;
  openForm: boolean;
  showId: string | null;
  listPermission: PermissionEntity[];
}

export const RolesContext = React.createContext({} as any);

export const RolesProvider = (props: any) => {
  return <RolesContext.Provider value={props}>{props.children}</RolesContext.Provider>;
};

export const RolesForm = (props: Props) => {
  const { detailRole, openForm, showId, listPermission } = props;
  const { methodForm, onSubmit, defaultValue } = useViewModel(props);
  const {
    watch,
    control,
    setValue,
    reset,
    formState: { isSubmitting },
  } = methodForm;

  const watchPermissions = watch().permissions;
  const { selectedPermissions } = useWatchPermission(
    watchPermissions,
    detailRole,
    showId,
    defaultValue,
    reset,
    openForm,
  );

  return (
    <RolesProvider {...selectedPermissions}>
      <FormProvider {...methodForm}>
        <form onSubmit={onSubmit}>
          <div className='w-full flex flex-wrap justify-between'>
            <div className='text-left'>
              <Typography.Text className='w-full'>Role name (*)</Typography.Text>
              <CoreInput control={control} name='name' className='w-full mb-10' />
            </div>

            <div className='text-left ml-6'>
              <Typography.Text className='w-full'>Status</Typography.Text>
              <CoreSwitch control={control} name='isActive' className='my-auto' />
            </div>
            <Typography.Text className='w-full'>Description (*)</Typography.Text>
            <CoreInput control={control} name='description' className='w-full mb-10' />
          </div>
          <div className='py-lg-8 '>
            <Tabs
              defaultActiveKey='1'
              items={listPermission.map((item, i) => {
                const id = get(item, 'id');
                return {
                  label: get(item, 'app'),
                  key: id,
                  children: (
                    <RolesCollapse
                      permission={get(item, 'permissions')}
                      name={get(item, 'app')}
                      setValue={setValue}
                    />
                  ),
                };
              })}
            />
          </div>

          <div className='text-right'>
            <Button htmlType='submit' type='primary' loading={isSubmitting}>
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </RolesProvider>
  );
};
