import { useBaseViewModel } from '@/common';
import { CityEntity } from '@/domains/city/entities';
import { QueryCityPayload } from '@/domains/city/ports/payloads';
import { GetAllCityUsecase } from '@/domains/city/usecases/get-all.usecase';
import { useState } from 'react';
import { CityHttpRepository } from '../adapters/repositories';

export default function GetAllCityViewModel() {
  const getAllCityUsecase = new GetAllCityUsecase(new CityHttpRepository());

  const { loading, error, catchAction } = useBaseViewModel();
  const [cityData, setCityData] = useState<CityEntity[]>();

  const actionGetAllCity = async (payload?: QueryCityPayload) => {
    await catchAction(async () => {
      const result = await getAllCityUsecase.run(payload);
      setCityData(result);
    });
  };

  return {
    loading,
    error,
    cityData,
    actionGetAllCity,
  };
}
