import { getErrorMessage } from '@/components/hooks/useGetErrorMessage';
import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { PermissionEntity } from '@/domain/entities/permission';
import { RolesEntity } from '@/domain/entities/roles';
import { RolesPayLoad } from '@/domain/ports/roles/payloads';
import { UpdateRolesUseCase, CreateRolesUseCase } from '@/domain/use-cases/roles';
import yup from '@/yupGlobal';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { RolesRepoIml } from '../adapters/repositories';

interface Props {
  detailRole: RolesEntity;
  setRefresh: (refresh: boolean) => void;
  openForm: boolean;
  showId: string | null;
  listPermission?: PermissionEntity[];
}

const useRolesForm = (props: Props) => {
  const { detailRole, setRefresh, openForm, listPermission } = props;
  const { handleClose } = useHandleActionItem();
  const [currentQueryParameters] = useSearchParams();
  const showId = currentQueryParameters.get('id');
  const createRoles = new CreateRolesUseCase(new RolesRepoIml());
  const updateRoles = new UpdateRolesUseCase(new RolesRepoIml());

  const formatRoles = useCallback(() => {
    if (showId && Array.isArray(detailRole.permissions) && detailRole.permissions.length > 0) {
      return detailRole.permissions.map((item) => {
        return {
          app: item.app,
          permissions: item.permissions,
        };
      });
    } else {
      if (Array.isArray(listPermission) && listPermission.length > 0) {
        const baseArr = listPermission.map((item) => {
          return {
            app: item.app,
            permissions: [],
          };
        });
        return baseArr;
      }
      return [];
    }
  }, [showId, openForm, listPermission, detailRole]);

  const defaultValue = {
    id: '',
    name: '',
    description: '',
    permissions: formatRoles(),
    isActive: true,
  };

  const methodForm = useForm({
    mode: 'onTouched',
    defaultValues: {
      id: detailRole.id ?? '',
      name: detailRole.name ?? '',
      description: detailRole.description ?? '',
      permissions: formatRoles(),
      isActive: detailRole.isActive ?? true,
    },
    resolver: yupResolver(
      yup.object({
        name: yup.string().required('Role Name is required'),
        description: yup.string().required('Description is required'),
      }),
    ),
  });

  const onSubmit = methodForm.handleSubmit(async (data: RolesPayLoad) => {
    try {
      if (!showId) {
        delete data.id;
        await createRoles.create(data);
        notification.success({
          message: 'You already have successfully created roles',
          placement: 'bottomRight',
        });
      } else {
        const dataRoleUpdate = data.permissions.map((item) => {
          return {
            app: item.app,
            permissions: item.permissions,
          };
        });
        const getDataRoleUpdate = {
          ...data,
          permissions: dataRoleUpdate,
        };
        await updateRoles.update(getDataRoleUpdate);
        notification.success({
          message: 'You already have successfully updated roles',
          placement: 'bottomRight',
        });
        setRefresh(true);
        handleClose();
        methodForm.reset({
          ...defaultValue,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getErrorMessage(error),
        placement: 'bottomRight',
      });
    }
  });

  return {
    methodForm,
    onSubmit,
    defaultValue,
  };
};

export default useRolesForm;
