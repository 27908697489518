// Generate code from clean architecture template

import { CountriesEntity } from '../entities';
import { CountriesPayloadForm } from '../ports/payloads';
import { CountriesRepository } from '../ports/repositories';

export class CreateCountriesUsecase {
  constructor(private readonly repo: CountriesRepository) {}

  async run(payload: CountriesPayloadForm): Promise<CountriesEntity> {
    const data = this.repo.createCountry(payload);
    return data;
  }
}
