import { useBaseViewModel } from '@/common';
import { CenterTable } from '@/domains/center/entities';
import { GetListCenterUsecase } from '@/domains/center/usecases';
import { useState } from 'react';
import { QueryCenterPayload } from '@/domains/center/ports/payloads';
import { CenterHttpRepository } from '../adapters/repositories';

export default function GetListCenter() {
  const getListCenterUC = new GetListCenterUsecase(new CenterHttpRepository());
  const { loading, error, catchAction } = useBaseViewModel();
  const [centerData, setCenterData] = useState<CenterTable[]>([]);

  const actionGetAllCenter = async (payload?: QueryCenterPayload) => {
    await catchAction(async () => {
      const data = await getListCenterUC.run(payload);
      setCenterData(data);
    });
  };

  return {
    loading,
    error,
    centerData,
    actionGetAllCenter,
  };
}
