import { gql } from '@apollo/client';
import { CityData, CityDataTable } from './city';

export const GET_LIST_CITY = gql`
query Cities_getList($query: QueryCitiesInput) {
  Cities_getList(query: $query) {
    data {
      ${CityDataTable}
    }
    limit
    count
    page
  }
}
`;

export const CREATE_CITY = gql`
mutation City_create($payload: CreateCityInput!) {
  City_create(createCityInput: $payload) {
    ${CityData}
  }
}
`;

export const UPDATE_CITY = gql`
mutation City_update($payload: UpdateCityInput!) {
  City_update(updateCityInput: $payload) {
    ${CityData}
  }
}
`;

export const GET_DETAIL_CITY = gql`
query City_getById($id: String!) {
  City_getById(id: $id) {
    ${CityData}
  }
}
`;

export const GET_ALL_CITY = gql`
query City_getAll($payload: QueryCityGetAllInput) {
  City_getAll(query: $payload) {
    ${CityData}
  }
}
`;

export const CHECK_EXISTED_CITY = gql`
query City_getByCriteria($payload: QueryCityCriteriaInput) {
  City_getByCriteria(query: $payload) {
    ${CityDataTable}
  }
}
`;
