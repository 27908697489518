import { CheckExistedCountryByCodeUsecase } from '@/domains/countries/usecases/check-existed';
import { useRef } from 'react';
import { CountriesHttpRepository } from '../adapters/repositories';
import { PropsCountryForm } from './countries-form.viewmodel';
import { get } from 'lodash';

export default function CheckExistCountryByCode(props: PropsCountryForm) {
  const checkExistedByCodeUC = new CheckExistedCountryByCodeUsecase(new CountriesHttpRepository());
  const abortControllerCode = useRef(new AbortController());
  const currentValue = useRef<string | undefined>(get(props, 'detailCountry.code'));

  const validateCode = async (value: string | undefined) => {
    if (value && value !== currentValue.current) {
      abortControllerCode.current.abort();
      abortControllerCode.current = new AbortController();
      const data = await checkExistedByCodeUC.run(value, abortControllerCode);
      if (data !== null) {
        return false;
      }
      return true;
    }
    return true;
  };

  return {
    validateCode,
  };
}
