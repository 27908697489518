import { Switch as AntSwitch } from 'antd';
import React from 'react';
import { Controller } from 'react-hook-form';

export type SwitchProps = {
  control: any;
  name: string;
  className?: string;
  loading?: boolean;
  checked?: boolean;
  formatValue?: boolean;
};

export const CoreSwitch: React.FC<SwitchProps> = (props) => {
  const { control, name, loading, className, formatValue } = props;

  return (
    <div className={className}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          const changeValue =
            String(value) === 'ACTIVE' ? true : String(value) === 'INACTIVE' ? false : value;
          return (
            <AntSwitch
              onChange={onChange}
              loading={loading}
              checked={formatValue ? changeValue : value}
            />
          );
        }}
      />
    </div>
  );
};
