import { RolesEntity } from '@/domain/entities/roles';
import { RolesPayLoad } from '@/domain/ports/roles/payloads';

import { RolesRepository } from '@/domain/ports/roles/repositories';

export class UpdateRolesUseCase {
  constructor(private readonly repository: RolesRepository) {}

  async update(payload: RolesPayLoad): Promise<RolesEntity> {
    const roles = await this.repository.update(payload);

    return roles;
  }
}
