import { useBaseViewModel } from '@/common';
import { AccountsEntity } from '@/domain/entities/account';
import { FindAccountByUseCase } from '@/domain/use-cases/accounts';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AccountRepoIml } from '../adapters/repositories';

export default function GetDetailAccount() {
  const getDetailAccount = new FindAccountByUseCase(new AccountRepoIml());
  const [currentQueryParameters] = useSearchParams();
  const getId = currentQueryParameters.get('id');

  const { loading, error, catchAction } = useBaseViewModel();
  const [detail, setDetail] = useState<{ data: AccountsEntity }>({
    data: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      roles: [
        {
          id: '',
          name: '',
        },
      ],
      isActive: true,
    },
  });

  const actionGetDetail = () => {
    if (getId) {
      catchAction(async () => {
        const { dataDetail } = await getDetailAccount.find(getId);
        setDetail({ data: dataDetail });
      });
    }
  };

  return {
    loading,
    error,
    detail,
    actionGetDetail,
  };
}
