import { CoreInput } from '@/components/Form/InputAntForm';
import CoreSelect from '@/components/Form/SelectAntForm';
import { CoreSwitch } from '@/components/Form/Switch';
import { useCoreContext } from '@/core';
import { CenterEntity } from '@/domains/center/entities';
import { Button, Typography } from 'antd';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { AddressInput } from '../../components/AddressInput';
import useViewModel from '../../viewmodels/center-form.viewmodel';
interface Props {
  detailCenter: CenterEntity;
  openForm: boolean;
}

export const CenterForm = (props: Props) => {
  const { openForm, detailCenter } = props;
  const { buData } = useCoreContext();
  const [currentQueryParameters] = useSearchParams();
  const showId = currentQueryParameters.get('id');
  const { methodForm, initialValue, onSubmit } = useViewModel(props);
  const {
    control,
    reset,
    formState: { isSubmitting, isDirty },
  } = methodForm;

  useEffect(() => {
    if (!showId) {
      reset(initialValue);
    }
  }, [openForm]);

  return (
    <FormProvider {...methodForm}>
      <form onSubmit={onSubmit}>
        <div className='w-full flex'>
          <div className='px-3 mb-6'>
            <Typography.Title level={4}>
              {showId ? get(detailCenter, 'name') : 'Create'}
            </Typography.Title>
          </div>
          <div className='ml-auto'>
            <CoreSwitch
              control={control}
              name='isActive'
              className='px-3 mt-2'
              formatValue={true}
            />
          </div>
        </div>
        <div className='w-full flex flex-wrap'>
          <CoreInput
            control={control}
            name='shortName'
            label='Shortname'
            className='w-1/2 mb-5 px-3'
          />
          <CoreInput control={control} name='name' label='name' className='w-1/2 mb-5 px-3' />
          <CoreInput control={control} name='hotLine' label='Hotline' className='w-1/2 mb-5 px-3' />
          <CoreInput control={control} name='email' label='email' className='w-1/2 mb-5 px-3' />
          <AddressInput className='w-full mb-5 px-3' showId={showId} detailCenter={detailCenter} />
          <CoreInput
            control={control}
            name='coordinate'
            label='Coordinate'
            className='w-full px-3 mb-3'
          />
          <CoreSelect
            control={control}
            name='businessUnits'
            label='Business Unit'
            mode='multiple'
            optionsProps={buData ?? []}
            displayField='name'
            valueField='id'
            filterOption={(inputValue, option) => {
              if (option) {
                return String(get(option, 'label'))
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
              }
              return true;
            }}
            className='w-1/2 px-3 mb-3'
          />
        </div>
        <div className='text-right px-3 mt-3'>
          <Button type='primary' htmlType='submit' loading={isSubmitting} disabled={!isDirty}>
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
