import { getErrorMessage } from '@/components/hooks/useGetErrorMessage';
import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { useCoreContext } from '@/core';
import { CityPayloadForm, PropsCityForm } from '@/domains/city/ports/payloads';
import { CreateCityUsecase, UpdateCityUsecase } from '@/domains/city/usecases';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { CityHttpRepository } from '../adapters/repositories';
import { validateCity } from '../validations';

const useCityForm = (props: PropsCityForm) => {
  const { detailCity } = props;

  const { actionGetListCity, getValues, reset } = useCoreContext();
  const { handleClose } = useHandleActionItem();
  const [currentQueryParameters] = useSearchParams();
  const showId = currentQueryParameters.get('id');
  const createCityUsecase = new CreateCityUsecase(new CityHttpRepository());
  const updateCityUsecase = new UpdateCityUsecase(new CityHttpRepository());

  const { schemaCity } = validateCity(props);

  const initialValue = {
    id: '',
    code: '',
    country: '',
    name: '',
    isActive: true,
  };

  const methodForm = useForm({
    mode: 'onTouched',
    defaultValues: {
      id: detailCity.id ?? '',
      code: detailCity.code ?? '',
      country: detailCity.country ?? '',
      name: detailCity.name ?? '',
      isActive: detailCity.isActive ?? true,
    },
    resolver: yupResolver(schemaCity),
  });

  const onSubmit = methodForm.handleSubmit(async (data: CityPayloadForm) => {
    try {
      if (!showId) {
        delete data.id;
        await createCityUsecase.run(data);
        notification.success({
          message: 'You already have successfully created city',
          placement: 'bottomRight',
        });
      } else {
        delete data.code;
        await updateCityUsecase.run(data);
        notification.success({
          message: 'You already have successfully updated city',
          placement: 'bottomRight',
        });
      }
      handleClose();
      methodForm.reset({
        ...initialValue,
      });

      reset();
      actionGetListCity(getValues());
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getErrorMessage(error),
        placement: 'bottomRight',
      });
    }
  });

  return {
    methodForm,
    onSubmit,
    initialValue,
  };
};

export default useCityForm;
