import { getErrorMessage } from '@/components/hooks/useGetErrorMessage';
import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { useCoreContext } from '@/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { WardHttpRepository } from '../adapters/repositories';
import { validateWard } from '../validations';
import { PropsWardForm, WardPayloadForm } from '@/domains/wards/ports/payloads';
import { CreateWardUsecase, UpdateWardUsecase } from '@/domains/wards/usecases';

const useWardForm = (props: PropsWardForm) => {
  const { detailWard } = props;
  const { actionGetListWard, getValues, reset } = useCoreContext();
  const { handleClose } = useHandleActionItem();
  const [currentQueryParameters] = useSearchParams();
  const showId = currentQueryParameters.get('id');
  const createWardUsecase = new CreateWardUsecase(new WardHttpRepository());
  const updateWardUsecase = new UpdateWardUsecase(new WardHttpRepository());

  const { schemaWard } = validateWard(props);

  const initialValue = {
    id: '',
    code: '',
    district: '',
    name: '',
    isActive: true,
    prefix: '',
  };

  const methodForm = useForm({
    mode: 'onTouched',
    defaultValues: {
      id: detailWard.id ?? '',
      code: detailWard.code ?? '',
      district: detailWard.district ?? '',
      name: detailWard.name ?? '',
      isActive: detailWard.isActive ?? true,
      prefix: detailWard.prefix ?? '',
    },
    resolver: yupResolver(schemaWard),
  });

  const onSubmit = methodForm.handleSubmit(async (data: WardPayloadForm) => {
    try {
      if (!showId) {
        delete data.id;
        await createWardUsecase.run(data);
        notification.success({
          message: 'You already have successfully created ward',
          placement: 'bottomRight',
        });
      } else {
        delete data.code;
        await updateWardUsecase.run(data);
        notification.success({
          message: 'You already have successfully updated ward',
          placement: 'bottomRight',
        });
      }
      handleClose();
      methodForm.reset({
        ...initialValue,
      });

      reset();
      actionGetListWard(getValues());
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getErrorMessage(error),
        placement: 'bottomRight',
      });
    }
  });

  return {
    methodForm,
    onSubmit,
    initialValue,
  };
};

export default useWardForm;
