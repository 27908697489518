import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { Button } from 'antd';
import React from 'react';
import { CenterProvider } from '../context';
import { CenterTable } from './List/CenterTable';
import { DetailCenter } from './Form';
import { Authorize } from '@/core';

export const CenterScreen: React.FC<unknown> = () => {
  const { open, handleClose, setOpen, handleClickCreate } = useHandleActionItem();

  return (
    <>
      <CenterProvider>
        <div className='bg-white py-4 px-4 flex flex-col flex-wrap'>
          <div className='ml-auto'>
            <Button
              type='primary'
              size='middle'
              onClick={() => handleClickCreate()}
              className='rounded'
            >
              + Create
            </Button>
          </div>
          <div className='w-full mt-2'>
            <CenterTable />
            <DetailCenter open={open} handleClose={handleClose} setOpen={setOpen} />
          </div>
        </div>
      </CenterProvider>
    </>
  );
};

export const CenterList = Authorize<unknown>(CenterScreen);
