import { useBaseViewModel } from '@/common';
import { RolesEntity } from '@/domain/entities/roles';
import { AllRolesUseCase } from '@/domain/use-cases/roles/getAll';
import { useState } from 'react';
import { RolesRepoIml } from '../adapters/repositories';

export default function GetListRole() {
  const getAllRole = new AllRolesUseCase(new RolesRepoIml());

  const { loading, error, catchAction } = useBaseViewModel();
  const [pageData, setPageData] = useState<{ Role_getAll: RolesEntity[] | [] }>({
    Role_getAll: [],
  });

  const actionGetAll = async () => {
    await catchAction(async () => {
      const data = await getAllRole.run();
      setPageData(data);
    });
  };

  return {
    loading,
    error,
    pageData,
    actionGetAll,
  };
}
