import { QueryWardPayload } from '../ports/payloads';
import { WardRepository } from '../ports/repositories';
import { ListWardPaging } from '../entities';

export class GetListWardUsecase {
  constructor(private readonly repo: WardRepository) {}

  async run(payload?: QueryWardPayload): Promise<ListWardPaging> {
    return this.repo.getListWard(payload);
  }
}
