import { EmployeeEntity } from '../entities';
import { UpdateEmployeePayload } from '../ports/payloads';
import { EmployeeRepository } from '../ports/repositories';

export class UpdateEmployeeDomainUsecase {
  constructor(private readonly repo: EmployeeRepository) {}

  run(payload: UpdateEmployeePayload): Promise<EmployeeEntity> {
    return this.repo.update(payload);
  }
}
