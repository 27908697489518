import { useBaseViewModel } from '@/common';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { WardHttpRepository } from '../adapters/repositories';
import { FindWardUseCase } from '@/domains/wards/usecases';
import { WardEntity } from '@/domains/wards/entities';

export default function GetDetailWard() {
  const getDetailWard = new FindWardUseCase(new WardHttpRepository());
  const [currentQueryParameters] = useSearchParams();
  const getId = currentQueryParameters.get('id');

  const { loading: loadingWard, error: errorWard, catchAction } = useBaseViewModel();
  const [detailWard, setDetailWard] = useState<{
    data: WardEntity;
  }>({
    data: {
      id: '',
      name: '',
      code: '',
      district: '',
      isActive: true,
      prefix: '',
    },
  });

  const actionGetDetail = () => {
    if (getId) {
      catchAction(async () => {
        const dataDetail = await getDetailWard.findById(getId);
        setDetailWard({ data: dataDetail });
      });
    }
  };

  return {
    loadingWard,
    errorWard,
    detailWard,
    actionGetDetail,
  };
}
