import CoreDrawer from '@/components/CoreDrawer';
import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { Spin, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useViewModel from '../../viewmodels/detail';
import { AccountForm } from './AccountForm';

export const Detail: React.FC = (): JSX.Element => {
  const { loading, error, detail, actionGetDetail } = useViewModel();
  const [currentQueryParameters] = useSearchParams();
  const showId = currentQueryParameters.get('id');
  const getAction = currentQueryParameters.get('action');
  const { open, handleClose, setOpen } = useHandleActionItem();

  useEffect(() => {
    if (showId !== null && getAction === 'Detail') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [open, handleClose, setOpen]);

  useEffect(() => {
    if (showId) {
      actionGetDetail();
    }
  }, [open]);

  const renderContent = () => {
    if (showId) {
      return (
        <>
          <div>{loading && <Spin />}</div>
          {error && <div>Error</div>}
          {!error && !loading && detail.data.id !== '' && (
            <AccountForm detailAccount={detail.data} openForm={open} showId={showId} />
          )}
        </>
      );
    }
    return null;
  };

  return (
    <CoreDrawer
      open={open}
      onClose={() => handleClose()}
      title={
        <div>
          <Typography.Title level={5}>Accounts</Typography.Title>
        </div>
      }
      content={renderContent()}
    />
  );
};
