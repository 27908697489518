import { PageProvider } from '@/core/context/PageContext';
import useViewModel from '../viewmodels/list';
import React, { useEffect } from 'react';
import { useTableFilter } from '@/core/hooks/useTableFilter';

export const AccountProvider = (props: any) => {
  const { pageData, actionGetAll, loading: loadingAccount, error: errorAccount } = useViewModel();
  const { dataList } = pageData;
  const { methodFilter } = useTableFilter({
    handleFetchData: actionGetAll,
    querySearch: { search: '' },
    queryFilters: { isActive: null, roles: [] },
    queryPagination: { page: 1, limit: 20 },
  });

  const { setValue, reset, getValues } = methodFilter;
  useEffect(() => {
    actionGetAll();
  }, []);

  const data = {
    dataList,
    actionGetAll,
    methodFilter,
    setValue,
    getValues,
    reset,
    loadingAccount,
    errorAccount,
  };

  return <PageProvider {...data}>{props.children}</PageProvider>;
};
