import React, { useContext } from 'react';
import { RolesContext } from '../UI/Form/RolesForm';

const useGetAppPermission = (permission: any, name: string) => {
  const data = JSON.parse(permission);
  const { selectedPermissions, setListItemChecked, listItemChecked } = useContext(RolesContext);

  const appSelectedPermissions = selectedPermissions
    .map((permission: any) => {
      if (permission.app === name) {
        return permission.permissions;
      } else {
        return [['']];
      }
    })
    .reduce((pre: any, cur: any) => {
      return pre.concat(cur);
    });

  const dataArray = Object.keys(data);

  const dataPermissions = {
    data: data,
    selectedPermissions: appSelectedPermissions,
    nameApp: name,
    listItemChecked: listItemChecked,
    setListItemChecked: setListItemChecked,
  };

  for (const prop in data) {
    if (typeof data[prop] !== 'object') {
      const obj: any = {};
      obj[prop] = data[prop];
      data[prop] = obj[prop];
    }
  }
  return {
    data,
    dataPermissions,
    dataArray,
  };
};

export default useGetAppPermission;
