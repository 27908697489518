import { CoreInput } from '@/components/Form/InputAntForm';
import CoreSelect from '@/components/Form/SelectAntForm';
import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { useCoreContext, useOpacity } from '@/core';
import { DistrictEntity } from '@/domains/district/entities';
import { EditOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React from 'react';

export const DistrictTableColumns = () => {
  const { control } = useCoreContext();
  const { opacity, setOpacity } = useOpacity();
  const { handleClickEdit } = useHandleActionItem();

  const optionsStatus = [
    {
      value: true,
      label: 'ACTIVE',
    },
    {
      value: false,
      label: 'INACTIVE',
    },
  ];

  const getColumnCountryNameProps = (): ColumnType<DistrictEntity> => ({
    filterDropdown: () => (
      <div onKeyDown={(e) => e.stopPropagation()}>
        <CoreInput control={control} name='search' />
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <>
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      </>
    ),
  });

  const getColumnStatusProps = (): ColumnType<DistrictEntity> => ({
    filterDropdown: () => (
      <div onKeyDown={(e) => e.stopPropagation()}>
        <CoreSelect control={control} name='isActive' optionsProps={optionsStatus} />
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <>
        <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      </>
    ),
  });

  const tableColumns: ColumnsType<DistrictEntity> = [
    {
      title: 'District name',
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      ...getColumnCountryNameProps(),
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'status',
      width: '20%',
      render: (record: boolean) => {
        return record === true ? 'Active' : 'Deactive';
      },
      ...getColumnStatusProps(),
    },
    {
      title: 'Last update at',
      dataIndex: 'lastModifiedAt',
      key: 'lastModifiedAt',
      width: '25%',
      render: (record: number) => {
        return record ? dayjs(Number(record)).format('DD/MM/YYYY') : 'N/A';
      },
    },
    {
      title: 'Action',
      width: '15%',
      key: 'action',
      render: (record: any) => {
        return (
          <div
            className='actions'
            onMouseOver={() => setOpacity(1)}
            onMouseLeave={() => setOpacity(0.6)}
          >
            <EditOutlined
              style={{ color: 'blue', cursor: 'pointer', opacity }}
              onClick={() => handleClickEdit(record)}
            />
          </div>
        );
      },
      fixed: 'right',
    },
  ];

  return tableColumns;
};
