import { get, set } from 'lodash';
import React from 'react';

const useGetOnePermission = (listItemChecked: any, nameApp: string, setListItemChecked: any) => {
  const getData = () => {
    const clone = [...listItemChecked];
    if (Array.isArray(clone)) {
      const index = clone.findIndex((item) => item.app == nameApp);
      if (index >= 0) {
        return clone[index].permissions;
      }
      return '';
    }
    return [{ permissions: '' }];
  };

  const isChecked = (key: string) => {
    if (Array.isArray(getData()) && getData().includes(key)) {
      return true;
    }
    return false;
  };

  const handleClick = (key: any) => {
    const clone = [...listItemChecked];
    if (Array.isArray(clone)) {
      const index = clone.findIndex((item) => item.app === nameApp);
      if (index >= 0) {
        let data = get(clone[index], 'permissions');
        if (Array.isArray(data)) {
          if (isChecked(key)) {
            data = [...data].filter((item) => item !== key);
          } else {
            data.push(key);
          }
          set(clone[index], 'permissions', data);
        }
      } else {
        let dataOne = get(clone[clone.length + 1], 'permissions');
        if (Array.isArray(dataOne)) {
          if (isChecked(key)) {
            dataOne = [...dataOne].filter((item) => item !== key);
          } else {
            dataOne.push(key);
          }
          set(clone[clone.length + 1], 'permissions', dataOne);
        }
      }
    }
    setListItemChecked(clone);
  };
  return {
    getData,
    isChecked,
    handleClick,
  };
};

export default useGetOnePermission;
