import { WardEntity } from '../entities';
import { WardPayloadForm } from '../ports/payloads';
import { WardRepository } from '../ports/repositories';

export class CreateWardUsecase {
  constructor(private readonly repo: WardRepository) {}

  async run(payload: WardPayloadForm): Promise<WardEntity> {
    const data = this.repo.createWard(payload);
    return data;
  }
}
