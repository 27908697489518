import { EmployeeEntity, WorkShift } from '../entities';
import { FindWorkShiftPayload } from '../ports/payloads';
import { EmployeeRepository } from '../ports/repositories';

export class FindEmployeeDomainUsecase {
  constructor(private readonly repo: EmployeeRepository) {}

  findById(id: string): Promise<EmployeeEntity> {
    return this.repo.findById(id);
  }

  findWorkShift(payload?: FindWorkShiftPayload): Promise<WorkShift[]> {
    return this.repo.findWorkShift(payload);
  }
}
