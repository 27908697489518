import { PageProvider } from '@/core/context/PageContext';
import { useTableFilter } from '@/core/hooks/useTableFilter';
import GetListCityViewModel from '@/modules/city/viewmodels/city-list.viewmodel';
import React, { useEffect } from 'react';
import GetListDistrictViewModel from '../viewmodels/district-list.viewmodel';

export const DistrictProvider = ({ children }: any) => {
  const { actionGetListDistrict, districtData, error, loading } = GetListDistrictViewModel();
  const { actionGetListCity, cityData } = GetListCityViewModel();

  const { methodFilter } = useTableFilter({
    handleFetchData: actionGetListDistrict,
    querySearch: { search: '' },
    queryFilters: { isActive: undefined },
    queryPagination: { page: 1, limit: 10 },
  });

  const { control, setValue, reset, getValues, watch } = methodFilter;

  const searchCity = async (search: string) => {
    await actionGetListCity({
      search,
      limit: 100,
      isActive: true,
    });
  };

  useEffect(() => {
    actionGetListDistrict(getValues());
    actionGetListCity();
  }, []);

  const data = {
    districtData,
    actionGetListDistrict,
    searchCity,
    cityData,
    error,
    loading,
    control,
    setValue,
    reset,
    getValues,
    watch,
  };

  return <PageProvider {...data}>{children}</PageProvider>;
};
