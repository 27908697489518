import { DistrictEntity } from '../entities';
import { DistrictPayloadForm } from '../ports/payloads';
import { DistrictRepository } from '../ports/repositories';

export class UpdateDistrictUsecase {
  constructor(private readonly repo: DistrictRepository) {}

  async run(payload: DistrictPayloadForm): Promise<DistrictEntity> {
    const data = this.repo.updateDistrict(payload);
    return data;
  }
}
