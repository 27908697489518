import { getErrorMessage } from '@/components/hooks/useGetErrorMessage';
import { EmployeeEntity } from '@/domains/employee/entities';
import { UpdateEmployeeDomainUsecase } from '@/domains/employee/usecases';
import { notification } from 'antd';
import { useForm } from 'react-hook-form';
import { EmployeeHttpRepository } from '../adapters/repositories';
import { useCoreContext } from '@/core';
import { useSearchParams } from 'react-router-dom';

const FormEmployeeViewmodel = (employee?: EmployeeEntity) => {
  const updateEmployeeDomainUsecase = new UpdateEmployeeDomainUsecase(new EmployeeHttpRepository());
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const { actionGetEmployee, handleClose } = useCoreContext();

  const methodForm = useForm({
    mode: 'onTouched',
    defaultValues: { ...employee },
  });

  const onSubmit = methodForm.handleSubmit(async (data) => {
    try {
      if (!id) {
        return;
      }

      await updateEmployeeDomainUsecase.run({ workShiftId: data.workShift?.id, id });
      await actionGetEmployee();

      handleClose();

      notification.success({
        message: 'You already have successfully updated city',
        placement: 'bottomRight',
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getErrorMessage(error),
        placement: 'bottomRight',
      });
    }
  });

  return {
    methodForm,
    onSubmit,
  };
};

export default FormEmployeeViewmodel;
