import { useBaseViewModel } from '@/common';
import { QueryDistrictGetAllPayload } from '@/domains/district/ports/payloads';
import { useState } from 'react';
import { DistrictHttpRepository } from '../adapters/repositories';
import { FindDistrictUseCase } from '@/domains/district/usecases/find.usecase';
import { DistrictEntity } from '@/domains/district/entities';

export default function GetAllListDistrictViewModel() {
  const findDistrictUseCase = new FindDistrictUseCase(new DistrictHttpRepository());

  const { loading, error, catchAction } = useBaseViewModel();
  const [districtData, setDistrictData] = useState<DistrictEntity[]>();

  const actionGetAllDistrict = async (payload?: QueryDistrictGetAllPayload) => {
    await catchAction(async () => {
      const result = await findDistrictUseCase.find(payload);
      setDistrictData(result);
    });
  };

  return {
    loading,
    error,
    districtData,
    actionGetAllDistrict,
  };
}
