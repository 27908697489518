import { config } from '@/config';
import _, { get } from 'lodash';

export const proxyUploadImage = async (file: File): Promise<string> => {
  const uploadEndpoint: string = _.get(config.base, 'uploadEndpoint', '');

  const formData = new FormData();
  formData.append('files', file);

  const res = await catchError(fetch(uploadEndpoint, { method: 'POST', body: formData }));

  const data = await res.json();

  if (!res.ok) {
    throw new Error(get(data, 'message'));
  }
  const relativeLink = get(data, 'link', '');
  return `${relativeLink}`;
};

const catchError = (fetcher: Promise<Response>): Promise<Response> => {
  return new Promise((resolve, reject) => {
    fetcher.then(resolve).catch(reject);
  });
};
