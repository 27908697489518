import { PropsCenterForm } from '@/domains/center/entities';
import * as yup from 'yup';

export const validateCenter = (props: PropsCenterForm) => {
  const schemaCenter = yup.object().shape({
    shortName: yup.string().required('ShortName is empty').trim(),
    name: yup.string().required('Name is empty').trim(),
    hotLine: yup.string().required('hotLine is empty').trim(),
    email: yup.string().required('Email is empty').trim(),
    address: yup.object({
      countryCode: yup.string().required('Country is empty'),
      cityCode: yup.string().required('City is empty'),
      districtCode: yup.string().required('District is empty'),
    }),
    coordinate: yup
      .string()
      .required('coordinate is empty')
      .matches(
        /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
        'coordinate not match',
      )
      .trim(),
  });

  return { schemaCenter };
};
