import { RolesProvider } from '../context';
import useViewModel from '../viewmodels/list';
import React, { useEffect, useState } from 'react';
import { RolesTable } from './List/RolesTable';
import { Detail } from './Form';
import { Authorize } from '@/core';
import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import RolesTableFilter from './List/RolesTableFilter';

export const RolesListScreen: React.FC<unknown> = () => {
  const { open, handleClose, setOpen, handleClickCreate } = useHandleActionItem();
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (refresh === true) {
      setRefresh(false);
    }
  }, [refresh]);
  return (
    <>
      <RolesProvider>
        <div className='bg-white py-10 px-lg-16 flex flex-col flex-wrap'>
          <div>
            <RolesTableFilter handleCreate={handleClickCreate} />
            <RolesTable />
            <Detail
              open={open}
              handleClose={handleClose}
              setOpen={setOpen}
              setRefresh={setRefresh}
            />
          </div>
        </div>
      </RolesProvider>
    </>
  );
};

export const RolesList = Authorize<unknown>(RolesListScreen);
