import { UsersEntity } from '@/domain/entities/users';
import { UsersRepository } from '@/domain/ports/users/repositories';

export class AllUsersUseCase {
  constructor(private readonly repository: UsersRepository) {}

  async run(payload: string): Promise<{ dataDetail: UsersEntity }> {
    const { dataDetail } = await this.repository.get(payload);

    return { dataDetail };
  }
}
