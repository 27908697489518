export const ImportEmployeeData = `
limit
page
total
data{
  total
  importId
  status
  id
  successCount
  failureCount
  path
  createdAt
  rows{
    employeeCode
    fullName
    status
    message
    importStatus
  }
}
`;

export const ImportEmployeeDetailData = `
total
importId
status
id
successCount
successCount
path
rows{
    employeeCode
    fullName
    status
    message
    importStatus
}
 `;
