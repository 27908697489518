import { useEffect, useState } from 'react';
import { getCustomTokenFromSSOServer, authenticate, navigateOnFailures } from '@/core';
import '@/core/firebase';
import { config } from '@/config';
import { SocketManager } from '@mx/socket/dist/socket';
import { getAuth } from 'firebase/auth';

const getIdToken = async () => {
  const idTokenInfo = await getAuth().currentUser!.getIdTokenResult();

  return idTokenInfo.token;
};
export const useSSOAuthentication = (authenticationRequired: boolean) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
  const [socketManager, setSocketManager] = useState<SocketManager | undefined>(undefined);

  const execSSOAuthenticate = async () => {
    try {
      const customToken = await getCustomTokenFromSSOServer();
      const { userId: newUserId } = await authenticate(customToken);
      const socketEndpoint = config.socketService.main;
      const socket = new SocketManager(getIdToken, socketEndpoint);

      setSocketManager(socket);
      if (newUserId) {
        setIsAuthenticated(true);
      } else {
        // setIsAuthorized(false);
      }
    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      execSSOAuthenticate();
    }
  }, []);

  navigateOnFailures(isAuthenticated, authenticationRequired);

  return {
    isAuthenticated,
    socketManager,
  };
};
