import CoreDrawer from '@/components/CoreDrawer';
import { useCoreContext } from '@/core';
import { Spin } from 'antd';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CenterForm } from './CenterForm';

interface Props {
  open: boolean;
  handleClose: () => void;
  setOpen: (value: boolean) => void;
}

export const DetailCenter = (props: Props) => {
  const { open, setOpen, handleClose } = props;
  const { loadingDetail, errorDetail, detailCenter } = useCoreContext();

  const [currentQueryParameters] = useSearchParams();
  const showId = currentQueryParameters.get('id');
  const getAction = currentQueryParameters.get('action');

  useEffect(() => {
    if ((showId !== null && getAction === 'Detail') || getAction === 'Create') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [open, handleClose, setOpen]);

  const renderContent = () => {
    if (showId) {
      return (
        <>
          <div>{loadingDetail && <Spin />}</div>
          {errorDetail && <div>Error</div>}
          {!errorDetail && !loadingDetail && get(detailCenter, 'data.id') !== '' && (
            <CenterForm detailCenter={get(detailCenter, 'data')} openForm={open} />
          )}
        </>
      );
    }
    return <CenterForm detailCenter={get(detailCenter, 'data')} openForm={open} />;
  };

  return (
    <CoreDrawer
      open={open}
      onClose={() => handleClose()}
      content={renderContent()}
      bodyStyle={{ marginTop: '20px' }}
    />
  );
};
