import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { useCoreContext, useOpacity } from '@/core';
import { RolesColumn } from '@/domain/entities/roles';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TimeCell } from '../../../components/TimeCell';
import useViewModel from '../viewmodels/delete';
import React from 'react';

export const RolesTableColumns = () => {
  const { opacity, setOpacity } = useOpacity();
  const { handleClickEdit, showConfirmDelete } = useHandleActionItem();
  const { loading, error, actionDelete } = useViewModel();
  const { actionGetAll } = useCoreContext();

  const renderContentDelete = () => {
    return (
      <>
        <p>Are you sure to delete this item?</p>
      </>
    );
  };

  const propsDelete = {
    content: renderContentDelete(),
    loading: loading,
    error: error,
    actionDelete: actionDelete,
    recordId: '',
    refresh: actionGetAll,
  };

  const tableColumns: ColumnsType<RolesColumn> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'status',
      render: (value: boolean) => {
        if (value === true) {
          return <Typography.Text type='success'>Active</Typography.Text>;
        }
        return <Typography.Text disabled>Deactivated</Typography.Text>;
      },
    },
    {
      title: 'Last update at',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (record) => {
        return <TimeCell value={record} />;
      },
    },
    {
      title: 'Create at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => {
        return <TimeCell value={record} />;
      },
    },
    {
      title: 'Action',
      width: '6rem',
      key: 'action',
      render: (record) => {
        return (
          <div
            className='actions flex justify-evenly'
            onMouseOver={() => setOpacity(1)}
            onMouseLeave={() => setOpacity(0.6)}
          >
            <EditOutlined
              style={{ color: 'blue', cursor: 'pointer', opacity }}
              onClick={() => handleClickEdit(record)}
            />
            <DeleteOutlined
              style={{ color: 'red', cursor: 'pointer', opacity }}
              onClick={() => {
                propsDelete.recordId = record.id;
                return showConfirmDelete(propsDelete);
              }}
            />
          </div>
        );
      },
      fixed: 'right',
    },
  ];

  return tableColumns;
};
