/* eslint-disable @typescript-eslint/no-explicit-any */
import { PageProvider } from '@/core/context/PageContext';
import React, { useEffect } from 'react';
import EmployeeViewModel from '../viewmodels/employee.viewmodel';
import { useTableFilter } from '@/core/hooks/useTableFilter';
import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import FindWorkShiftViewModel from '../viewmodels/find-work-shift.viewmodel';
import ImportEmployeeViewModel from '../viewmodels/import-employee';
import { config } from '@/config';
import { AGGREGATE, DOMAIN, EVENT, TENANT } from '@mx/socket/dist/constants';
import { useAppContext } from '@/core';

const defaultSearch = {
  textSearch: '',
};
const defaultSearchImport = {};

export const EmployeeProvider = (props: any) => {
  const { actionGetEmployee, dataListEmployee, actionImportEmployee } = EmployeeViewModel();
  const {
    actionGetDetailEmployee,
    actionGetListImportEmployee,
    dataListImportEmployee,
    dataImportEmployee,
    loading,
    error,
  } = ImportEmployeeViewModel();
  const { actionFetchWorkShift, workShifts } = FindWorkShiftViewModel();
  const { methodFilter } = useTableFilter({
    handleFetchData: actionGetEmployee,
    querySearch: defaultSearch,
    queryFilters: {},
    queryPagination: {
      page: 1,
      limit: 20,
    },
  });
  const { methodFilter: methodFilterImportEmployee } = useTableFilter({
    handleFetchData: actionGetListImportEmployee,
    querySearch: defaultSearchImport,
    queryFilters: {},
    queryPagination: {
      page: 1,
      limit: 20,
    },
  });
  const { control, setValue, reset, getValues, watch } = methodFilter;
  const { socketManager } = useAppContext();

  const { open, handleClose, setOpen, handleClickEdit, handleClickCreate } = useHandleActionItem();

  useEffect(() => {
    actionGetEmployee(getValues() as any);
    actionFetchWorkShift();
  }, []);

  useEffect(() => {
    if (!socketManager) return;

    const channel = socketManager.generateChannel({
      tenant: TENANT.SINGLE,
      domain: DOMAIN.BO,
      aggregate: AGGREGATE.IMPORT_EMPLOYEE,
    });

    socketManager.addSocketInstance(channel).then(() => {
      socketManager.subscribe({
        channel,
        events: [EVENT.SOCKET_IMPORT_EMPLOYEE_CHANNEL],
        callback: () => {
          actionGetEmployee(getValues() as any);
          actionGetListImportEmployee();
        },
      });
    });

    return () => {
      socketManager.unsubscribe({
        channel,
        events: [EVENT.SOCKET_IMPORT_EMPLOYEE_CHANNEL],
        callback: (data: any) => console.log(data),
      });
    };
  }, [socketManager]);
  const data = {
    actionGetEmployee,
    dataListEmployee,
    control,
    setValue,
    reset,
    getValues,
    watch,
    methodFilter,
    actionImportEmployee,
    open,
    handleClose,
    setOpen,
    handleClickEdit,
    workShifts,
    handleClickCreate,
    actionGetDetailEmployee,
    actionGetListImportEmployee,
    dataListImportEmployee,
    dataImportEmployee,
    loading,
    error,
    methodFilterImportEmployee,
  };
  return <PageProvider {...data}>{props.children}</PageProvider>;
};
