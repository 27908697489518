import { CenterEntity } from '../entities';
import { CenterPayloadForm } from '../ports/payloads';
import { CenterRepository } from '../ports/repositories';

export class UpdateCenterUsecase {
  constructor(private readonly repo: CenterRepository) {}

  async run(payload: CenterPayloadForm): Promise<CenterEntity> {
    const data = await this.repo.updateCenter(payload);
    return data;
  }
}
