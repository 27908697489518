import { DistrictEntity } from '../entities';
import { DistrictRepository } from '../ports/repositories';

export class CheckExistedDistrictByCodeUsecase {
  constructor(private readonly repo: DistrictRepository) {}

  async run(code: string, abort: any): Promise<DistrictEntity | null> {
    const data = await this.repo.checkExistDistrictByCode(code, abort);
    return data;
  }
}
