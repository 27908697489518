import { get } from 'lodash';
import { useRef } from 'react';
import { WardHttpRepository } from '../adapters/repositories';
import { CheckExistedWardByCodeUsecase } from '@/domains/wards/usecases';
import { PropsWardForm } from '@/domains/wards/ports/payloads';

export default function CheckExistWardByCode(props: PropsWardForm) {
  const checkExistedByCodeUC = new CheckExistedWardByCodeUsecase(new WardHttpRepository());
  const abortControllerCode = useRef(new AbortController());
  const currentValue = useRef<string | undefined>(get(props, 'detailWard.code'));

  const validateCode = async (value: string | undefined) => {
    if (value && value !== currentValue.current) {
      abortControllerCode.current.abort();
      abortControllerCode.current = new AbortController();
      const data = await checkExistedByCodeUC.run(value, abortControllerCode);
      if (data !== null) {
        return false;
      }
      return true;
    }
    return true;
  };

  return {
    validateCode,
  };
}
