import { CityEntity } from '../entities';
import { CityPayloadForm } from '../ports/payloads';
import { CityRepository } from '../ports/repositories';

export class UpdateCityUsecase {
  constructor(private readonly repo: CityRepository) {}

  async run(payload: CityPayloadForm): Promise<CityEntity> {
    const data = this.repo.updateCity(payload);
    return data;
  }
}
