import { RolesEntity } from '@/domain/entities/roles';
import { RolesPayLoad } from '@/domain/ports/roles/payloads';

import { RolesRepository } from '@/domain/ports/roles/repositories';

export class CreateRolesUseCase {
  constructor(private readonly repository: RolesRepository) {}

  async create(payload: RolesPayLoad): Promise<RolesEntity> {
    const roles = await this.repository.create(payload);
    return roles;
  }
}
