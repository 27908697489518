import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, notification } from 'antd';
import React, { useState } from 'react';
import { getErrorMessage } from '../hooks/useGetErrorMessage';
import { proxyUploadImage } from './proxy';

interface Props {
  // label: string;
  onFinish: (url: string) => void;
  setLoading: (value: boolean) => void;
  accept?: string;
  allowExt?: RegExp;
  maxSize?: number;
  multiple?: boolean;
  disabled?: boolean;
}
const UploadAttachment = (props: Props) => {
  const { onFinish, setLoading, multiple } = props;
  const [uploading, setUploading] = useState<boolean>(false);

  const handleBeforeUploadAttachment = async (file: File) => {
    if (props.allowExt && !props.allowExt.test(file.name)) {
      notification.error({
        message: 'Invalid file type',
        description: 'Only images are allowed',
        placement: 'bottomRight',
      });
    } else if (props.maxSize && file.size > props.maxSize) {
      notification.error({
        message: 'File too large',
        description: 'File too large (<= 5MB)',
        placement: 'bottomRight',
      });
    } else {
      try {
        setUploading(true);
        setLoading(true);
        const response = await proxyUploadImage(file);
        onFinish(response);
      } catch (error) {
        notification.error({
          message: 'Error',
          description: getErrorMessage(error),
          placement: 'bottomRight',
        });
      } finally {
        setUploading(false);
        setLoading(false);
      }
    }
  };
  return (
    <Upload
      beforeUpload={(file: any) => {
        handleBeforeUploadAttachment(file);
        return false;
      }}
      accept={props.accept}
      multiple={multiple}
      showUploadList={false}
    >
      <Button
        type='primary'
        loading={uploading}
        icon={<UploadOutlined />}
        disabled={props.disabled}
      >
        Upload file
      </Button>
    </Upload>
  );
};

export default UploadAttachment;
