import { useBaseViewModel } from '@/common';
import { ListDistrict } from '@/domains/center/entities';
import { QueryDistrictPayload } from '@/domains/district/ports/payloads';
import { GetListDistrictUsecase } from '@/domains/district/usecases';
import { useState } from 'react';
import { DistrictHttpRepository } from '../adapters/repositories';

export default function GetListDistrictViewModel() {
  const getListDistrictUsecase = new GetListDistrictUsecase(new DistrictHttpRepository());

  const { loading, error, catchAction } = useBaseViewModel();
  const [districtData, setDistrictData] = useState<ListDistrict>();

  const actionGetListDistrict = async (payload?: QueryDistrictPayload) => {
    await catchAction(async () => {
      const result = await getListDistrictUsecase.run(payload);
      setDistrictData(result);
    });
  };

  return {
    loading,
    error,
    districtData,
    actionGetListDistrict,
  };
}
