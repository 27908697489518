import { FindRolesPayload } from '@/domain/ports/roles/payloads';
import { RolesRepository } from '@/domain/ports/roles/repositories';

export class RolesListUseCase {
  constructor(private readonly repository: RolesRepository) {}

  async run(payload?: FindRolesPayload) {
    const { data } = await this.repository.get(payload);
    return { data };
  }
}
