import { Pagination, PaginationProps } from 'antd';
import React from 'react';

interface Props extends PaginationProps {
  total: number;
}

export const CorePagination = (props: Props) => {
  const { total, ...restProps } = props;

  return <Pagination total={total} {...restProps} />;
};
