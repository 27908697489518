import React from 'react';
import { Input as AntInput, Typography } from 'antd';
import { InputProps as AntInputProps } from 'antd/lib/input';
import { Controller } from 'react-hook-form';

export type InputProps = AntInputProps & {
  control: any;
  value?: string;
  name: string;
  disabled?: boolean;
  type?: string;
  row?: number;
  label?: string;
  required?: boolean;
};
const { TextArea } = AntInput;

export const CoreInput: React.FC<InputProps> = (props) => {
  const { disabled, className, control, name, type, row, label, required, ...nativeProps } = props;
  // const decoratedClassName = getDecoratedClassName(hasError, className);

  return (
    <div className={className}>
      {label && (
        <Typography.Text className='w-full'>
          {label} {required ? '*' : ''}
        </Typography.Text>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <>
            {type === 'area' ? (
              <TextArea onChange={onChange} rows={row} value={value} />
            ) : (
              <AntInput
                {...nativeProps}
                value={value}
                onChange={onChange}
                disabled={disabled}
                onBlur={onBlur}
                status={error ? 'error' : ''}
              />
            )}
            {error && <p className='italic text-[#F5222D]'>{error.message}</p>}
          </>
        )}
      />
    </div>
  );
};

// function getDecoratedClassName(hasError: boolean | undefined, className: string | undefined) {
//   if (hasError === undefined) {
//     return className;
//   }
//   if (hasError) {
//     return `${className || ''} text-red`;
//   }
//   return `${className || ''} text-blue`;
// }

// DecoratedInput = Object.assign(DecoratedInput, { Search: AntInput.Search });
// DecoratedInput = Object.assign(DecoratedInput, { Group: AntInput.Group });
// DecoratedInput = Object.assign(DecoratedInput, { Password: AntInput.Password });
// DecoratedInput = Object.assign(DecoratedInput, { TextArea: AntInput.TextArea });
