import CoreDrawer from '@/components/CoreDrawer';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import FindByIdEmployeeViewModel from '../viewmodels/find-by-id.viewmodel';
import { Spin } from 'antd';
import { FormEmployee } from './FormEmployee';
import { useCoreContext } from '@/core';

export const DrawEmployee: React.FC = () => {
  const { open, handleClose, setOpen } = useCoreContext();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const type = searchParams.get('action');

  const { actionFetchEmployeeById, employee, error, loading } = FindByIdEmployeeViewModel();

  useEffect(() => {
    if (id) {
      actionFetchEmployeeById(id);
    }
  }, [id]);

  useEffect(() => {
    if (id && type === 'Detail') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [id]);

  const renderContent = () => {
    return (
      <>
        {
          <div>
            {loading && <Spin />} {error && <>Error</>}
            {employee?.id && <FormEmployee detail={employee} />}
          </div>
        }
      </>
    );
  };
  return <CoreDrawer open={open} onClose={handleClose} content={renderContent()} />;
};
