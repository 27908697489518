import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import useViewModel from '../../../menu-items/viewmodels/list';

export const useGetTitle = () => {
  const [title, setTitle] = useState<string>('');
  const { pageData } = useViewModel();
  const { dataList } = pageData;

  const hanldeTittle = (path: string) => {
    if (path === 'menu-items') {
      setTitle('Menu Item');
    } else {
      if (dataList) {
        const dataTitle = dataList.find((i) => i?.url === path);
        if (dataTitle) {
          setTitle(dataTitle.name);
        }
      }
    }
  };

  // useEffect(() => {
  //   actionGetAll({ isActive: true });
  // }, []);

  return { title, setTitle, hanldeTittle };
};
