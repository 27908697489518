import { DistrictEntity } from '../entities';
import { QueryDistrictGetAllPayload } from '../ports/payloads';
import { DistrictRepository } from '../ports/repositories';

export class FindDistrictUseCase {
  constructor(private readonly repository: DistrictRepository) {}

  async findById(id: string): Promise<DistrictEntity> {
    const data = this.repository.getDetailDistrict(id);
    return data;
  }

  find(query?: QueryDistrictGetAllPayload): Promise<DistrictEntity[]> {
    return this.repository.find(query);
  }
}
