import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, notification } from 'antd';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getErrorMessage } from './useGetErrorMessage';

const { confirm } = Modal;

interface PropsDelete {
  content: React.ReactNode;
  loading: boolean;
  error: Error | undefined;
  actionDelete: (id: string) => void;
  recordId: string;
  setRefreshList?: (refresh: boolean) => void;
  refresh?: () => void;
}

export const useHandleActionItem = () => {
  const [currentQueryParameters, setSearchParams] = useSearchParams();
  const newQueryParameters: URLSearchParams = new URLSearchParams();
  const [open, setOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleClickEdit = (value: any) => {
    if (value) {
      newQueryParameters.set('id', value.id);
      newQueryParameters.set('action', 'Detail');
      setSearchParams(newQueryParameters);
      setOpen(true);
    }
  };

  const handleClose = () => {
    const action = currentQueryParameters.get('action');
    const id = currentQueryParameters.get('id');
    if ((action && id) || action) {
      currentQueryParameters.delete('action');
      currentQueryParameters.delete('id');
      setSearchParams(currentQueryParameters);
    }
    setOpen(false);
  };

  const handleClickCreate = () => {
    newQueryParameters.set('action', 'Create');
    setSearchParams(newQueryParameters);
    setOpen(true);
  };

  const showConfirmDelete = (props: PropsDelete) => {
    const { content, error, actionDelete, recordId, setRefreshList, refresh } = props;
    confirm({
      title: 'Confirm Delete',
      icon: <ExclamationCircleOutlined />,
      content: content,
      onOk() {
        try {
          actionDelete(recordId);
          if (setRefreshList) {
            setRefreshList(true);
          } else if (refresh) {
            refresh();
          }
        } catch (e) {
          notification.error({
            message: 'Error',
            description: getErrorMessage(error),
            placement: 'bottomRight',
          });
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return {
    handleClickEdit,
    open,
    handleClose,
    setOpen,
    handleClickCreate,
    openModal,
    setOpenModal,
    showConfirmDelete,
  };
};
