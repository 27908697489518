import { RolesEntity } from '@/domain/entities/roles';
import { RolesRepository } from '@/domain/ports/roles/repositories';

export class FindRolesByUseCase {
  constructor(private readonly repository: RolesRepository) {}

  async find(payload: string): Promise<{ dataDetail: RolesEntity }> {
    const { dataDetail } = await this.repository.find(payload);

    return { dataDetail };
  }
}
