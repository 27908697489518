import { PropsDistrictForm } from '@/domains/district/ports/payloads';
import { CheckExistedDistrictByCodeUsecase } from '@/domains/district/usecases/check-existed';
import { get } from 'lodash';
import { useRef } from 'react';
import { DistrictHttpRepository } from '../adapters/repositories';

export default function CheckExistDistrictByCode(props: PropsDistrictForm) {
  const checkExistedByCodeUC = new CheckExistedDistrictByCodeUsecase(new DistrictHttpRepository());
  const abortControllerCode = useRef(new AbortController());
  const currentValue = useRef<string | undefined>(get(props, 'detailDistrict.code'));

  const validateCode = async (value: string | undefined) => {
    if (value && value !== currentValue.current) {
      abortControllerCode.current.abort();
      abortControllerCode.current = new AbortController();
      const data = await checkExistedByCodeUC.run(value, abortControllerCode);
      if (data !== null) {
        return false;
      }
      return true;
    }
    return true;
  };

  return {
    validateCode,
  };
}
