import { CityEntity } from '../entities';
import { QueryCityPayload } from '../ports/payloads';
import { CityRepository } from '../ports/repositories';

export class GetAllCityUsecase {
  constructor(private readonly repo: CityRepository) {}

  async run(payload?: QueryCityPayload): Promise<CityEntity[]> {
    return this.repo.getAllCity(payload);
  }
}
