export const CityData = `
    id
    code
    name
    country
    isActive
    lastModifiedAt
`;

export const CityDataTable = `
id
code
name
isActive
lastModifiedAt
`;
