import { WardEntity } from '../entities';
import { QueryWardPayload } from '../ports/payloads';
import { WardRepository } from '../ports/repositories';

export class GetAllWardUsecase {
  constructor(private readonly repo: WardRepository) {}

  async run(payload?: QueryWardPayload): Promise<WardEntity[]> {
    return this.repo.getAllWard(payload);
  }
}
