import { useBaseViewModel } from '@/common';
import { useState } from 'react';
import { WardHttpRepository } from '../adapters/repositories';
import { GetListWardUsecase } from '@/domains/wards/usecases';
import { ListWardPaging } from '@/domains/wards/entities';
import { QueryWardPayload } from '@/domains/wards/ports/payloads';

export default function GetListWardViewModel() {
  const getListWardUsecase = new GetListWardUsecase(new WardHttpRepository());

  const { loading, error, catchAction } = useBaseViewModel();
  const [wardData, setWardData] = useState<ListWardPaging>();

  const actionGetListWard = async (payload?: QueryWardPayload) => {
    await catchAction(async () => {
      const result = await getListWardUsecase.run(payload);
      setWardData(result);
    });
  };

  return {
    loading,
    error,
    wardData,
    actionGetListWard,
  };
}
