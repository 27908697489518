import { AccountsEntity } from '@/domain/entities/account';
import { AccountsRepository } from '@/domain/ports/account/repositories';

export class FindAccountByUseCase {
  constructor(private readonly repository: AccountsRepository) {}

  async find(payload: string): Promise<{ dataDetail: AccountsEntity }> {
    const { dataDetail } = await this.repository.find(payload);

    return { dataDetail };
  }
}
