export const RolesData = `
  id
  name
  description
  permissions{
    app
    permissions
  }
  isActive
  createdAt
  updatedAt
`;
