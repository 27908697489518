// Generate code from clean architecture template

import { ListDistrict } from '@/domains/center/entities';
import { QueryDistrictPayload } from '../ports/payloads';
import { DistrictRepository } from '../ports/repositories';

export class GetListDistrictUsecase {
  constructor(private readonly repo: DistrictRepository) {}

  async run(payload?: QueryDistrictPayload): Promise<ListDistrict> {
    return this.repo.getListDistrict(payload);
  }
}
