import { useBaseViewModel } from '@/common';
import { CenterEntity } from '@/domains/center/entities';
import { FindCenterByUseCase } from '@/domains/center/usecases/find.usecase';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CenterHttpRepository } from '../adapters/repositories';

export default function GetDetailCenter() {
  const getDetailCenter = new FindCenterByUseCase(new CenterHttpRepository());
  const [currentQueryParameters] = useSearchParams();
  const getId = currentQueryParameters.get('id');

  const { loading, error, catchAction } = useBaseViewModel();
  const [detailCenter, setDetailCenter] = useState<{
    data: CenterEntity;
  }>({
    data: {
      id: '',
      name: '',
      shortName: '',
      hotLine: '',
      email: '',
      isActive: true,
      address: {
        countryCode: '',
        cityCode: '',
        districtCode: '',
        wardCode: '',
        address: '',
      },
      coordinate: '',
      businessUnits: [],
    },
  });

  const actionGetDetailCenter = () => {
    if (getId) {
      catchAction(async () => {
        const dataDetail = await getDetailCenter.find(getId);
        setDetailCenter({ data: dataDetail });
      });
    }
  };

  return {
    loading,
    error,
    detailCenter,
    actionGetDetailCenter,
  };
}
