/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCoreContext, useOpacity } from '@/core';
import { EmployeeEntity } from '@/domains/employee/entities';
import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';

const EmployeeTableColumns = () => {
  const { opacity, setOpacity } = useOpacity();
  const { handleClickEdit } = useCoreContext();

  const tableColumns: ColumnsType<EmployeeEntity> = [
    {
      title: 'Employee code',
      dataIndex: 'employeeCode',
      key: 'employeeCode',
      width: '5%',
    },
    {
      title: 'Full name',
      dataIndex: 'fullName',
      key: 'fullName',
      width: '15%',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: '15%',
    },
    {
      title: 'Phone number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: '10%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
    },
    {
      title: 'Work email',
      dataIndex: 'workEmail',
      key: 'workEmail',
      width: '10%',
    },
    {
      title: 'Department',
      dataIndex: 'department.name',
      key: 'department.name',
      width: '10%',
      render: (_: any, record: any) => {
        return <div>{record?.department?.name}</div>;
      },
    },
    {
      title: 'Department unit',
      width: '10%',
      render: (_: any, record: any) => {
        return <div>{record?.department?.division?.name}</div>;
      },
    },

    {
      title: 'Action',
      width: '15%',
      key: 'action',
      render: (record: any) => {
        return (
          <div
            className='actions'
            onMouseOver={() => setOpacity(1)}
            onMouseLeave={() => setOpacity(0.6)}
          >
            <EditOutlined
              style={{ color: 'blue', cursor: 'pointer', opacity }}
              onClick={() => handleClickEdit(record)}
            />
          </div>
        );
      },
      fixed: 'right',
    },
  ];
  return tableColumns;
};

export default EmployeeTableColumns;
