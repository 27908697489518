import { gql } from '@apollo/client';

export const GET_ALL_BU = gql`
  query BusinessUnit_getAll {
    BusinessUnit_getAll {
      id
      name
    }
  }
`;
