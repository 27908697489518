import { RolesEntity } from '@/domain/entities/roles';
import { FindRolesPayload, RolesPayLoad } from '@/domain/ports/roles/payloads';
import { RolesRepository } from '@/domain/ports/roles/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import {
  FIND_ROLES,
  GET_ROLES_ITEMS,
  UPDATE_ROLES,
  CREATE_ROLES,
  GET_ROLES_ALLS,
  DELETE_ROLES,
} from '../../graphql';

export class RolesRepoIml implements RolesRepository {
  async get(payload?: FindRolesPayload): Promise<{ data: RolesEntity[] }> {
    const response: RolesEntity[] = await HttpClientAdapter.query<RolesEntity[]>({
      query: GET_ROLES_ITEMS,
      dataKey: 'Role_getList',
      variables: { query: { ...payload } },
    });
    return {
      data: response,
    };
  }

  async getAll(): Promise<{ Role_getAll: RolesEntity[] }> {
    const response: { Role_getAll: RolesEntity[] } = await HttpClientAdapter.query<{
      Role_getAll: RolesEntity[];
    }>({
      query: GET_ROLES_ALLS,
    });
    return response;
  }

  async create(payload: RolesPayLoad): Promise<RolesEntity> {
    const response = await HttpClientAdapter.mutate<RolesEntity>({
      mutation: CREATE_ROLES,
      variables: { payload: { ...payload } },
    });

    return response;
  }

  async update(payload: RolesPayLoad): Promise<RolesEntity> {
    const response = await HttpClientAdapter.mutate<RolesEntity>({
      mutation: UPDATE_ROLES,
      variables: { payload: { ...payload } },
    });
    return response;
  }

  async find(payload: string): Promise<{ dataDetail: RolesEntity }> {
    const response: RolesEntity = await HttpClientAdapter.query<RolesEntity>({
      query: FIND_ROLES,
      dataKey: 'Role_getById',
      variables: { id: payload },
    });
    return {
      dataDetail: response,
    };
  }

  async delete(payload: string): Promise<{ dataDeleted: RolesEntity }> {
    const response: RolesEntity = await HttpClientAdapter.mutate<RolesEntity>({
      mutation: DELETE_ROLES,
      variables: { id: payload },
    });
    return {
      dataDeleted: response,
    };
  }
}
