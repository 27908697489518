import { CityEntity } from '../entities';
import { CityRepository } from '../ports/repositories';

export class CheckExistedCityByCodeUsecase {
  constructor(private readonly repo: CityRepository) {}

  async run(code: string, abort: any): Promise<CityEntity | null> {
    const data = await this.repo.checkExistCityByCode(code, abort);
    return data;
  }
}
