import { PageProvider } from '@/core/context/PageContext';
import useViewModel from '../viewmodels/list';
import React, { useEffect } from 'react';
import { useTableFilter } from '@/core/hooks/useTableFilter';

export const RolesProvider = (props: any) => {
  const { pageData, actionGetAll, loading: loadingRoles, error: errorRoles } = useViewModel();
  const { dataList } = pageData;
  const { methodFilter } = useTableFilter({
    handleFetchData: actionGetAll,
    querySearch: { search: '' },
    queryFilters: { isActive: null },
    queryPagination: { page: 1, limit: 20 },
  });
  const { control, setValue, reset, getValues } = methodFilter;

  useEffect(() => {
    actionGetAll();
  }, []);

  const data = {
    dataList,
    actionGetAll,
    methodFilter,
    setValue,
    loadingRoles,
    errorRoles,
    getValues,
    reset,
  };

  return <PageProvider {...data}>{props.children}</PageProvider>;
};
