import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { useOpacity } from '@/core';
import { AccountsColumn, AccountsEntity } from '@/domain/entities/account';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { get } from 'lodash';
import React from 'react';

export const AccountTableColumns = () => {
  const { opacity, setOpacity } = useOpacity();
  const { handleClickEdit } = useHandleActionItem();

  const tableColumns: ColumnsType<AccountsColumn> = [
    {
      title: 'Name',
      key: 'name',
      render: (record: AccountsEntity) => {
        return `${get(record, 'lastName', '')} ${get(record, 'firstName', '')}`;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      render: (record: any) => {
        if (Array.isArray(record) && record.length > 0) {
          return record.map((item: any, index: number) => {
            return (
              <div style={{ marginTop: '10px' }} key={item.id}>
                <Tag color='blue'>{item.name}</Tag>
              </div>
            );
          });
        }
      },
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'status',
      render: (value: boolean) => {
        if (value === true) {
          return <Typography.Text type='success'>Active</Typography.Text>;
        }
        return <Typography.Text disabled>Deactivated</Typography.Text>;
      },
    },
    {
      title: 'Action',
      width: '6rem',
      key: 'action',
      render: (record) => {
        return (
          <div
            className='actions'
            onMouseOver={() => setOpacity(1)}
            onMouseLeave={() => setOpacity(0.6)}
          >
            <EditOutlined
              style={{ color: 'blue', cursor: 'pointer', opacity }}
              onClick={() => handleClickEdit(record)}
            />
          </div>
        );
      },
      fixed: 'right',
    },
  ];

  return tableColumns;
};
