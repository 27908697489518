import { useBaseViewModel } from '@/common';

import { RolesEntity } from '@/domain/entities/roles';
import { FindRolesByUseCase } from '@/domain/use-cases/roles';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RolesRepoIml } from '../adapters/repositories';

export default function GetDetailRoles() {
  const getDetailRoles = new FindRolesByUseCase(new RolesRepoIml());
  const [currentQueryParameters] = useSearchParams();
  const getId = currentQueryParameters.get('id');

  const { loading: loadingDetailRoles, error: errorDetailRoles, catchAction } = useBaseViewModel();
  const [detailRoles, setDetailRoles] = useState<{ data: RolesEntity }>({
    data: {
      id: '',
      name: '',
      description: '',
      permissions: [{ app: '', permissions: [''] }],
      isActive: true,
    },
  });

  const actionGetDetail = () => {
    if (getId) {
      catchAction(async () => {
        const { dataDetail } = await getDetailRoles.find(getId);
        setDetailRoles({ data: dataDetail });
      });
    }
  };

  return {
    loadingDetailRoles,
    errorDetailRoles,
    detailRoles,
    actionGetDetail,
  };
}
