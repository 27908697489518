import { BusinessUnitEntity } from '../entities';
import { BusinessUnitRepository } from '../ports/repositories/business-unit.repository';

export class GetAllBusinessUnitUsecase {
  constructor(private readonly repo: BusinessUnitRepository) {}

  async run(): Promise<BusinessUnitEntity[]> {
    const data = await this.repo.getAllBU();
    return data;
  }
}
