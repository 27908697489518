import { gql } from '@apollo/client';
import { DistrictData } from './district';

export const GET_LIST_DISTRICT = gql`
query Districts_getList($query: QueryDistrictsInput) {
  Districts_getList(query: $query) {
    data {
      ${DistrictData}
    }
    limit
    count
    page
  }
}
`;

export const CREATE_DISTRICT = gql`
mutation District_create($payload: CreateDistrictInput!) {
  District_create(createDistrictInput: $payload) {
    ${DistrictData}
  }
}
`;

export const UPDATE_DISTRICT = gql`
mutation District_update($payload: UpdateDistrictInput!) {
  District_update(updateDistrictInput: $payload) {
    ${DistrictData}
  }
}
`;

export const GET_DETAIL_DISTRICT = gql`
query District_getById($id: String!) {
  District_getById(id: $id) {
    ${DistrictData}
  }
}
`;

export const CHECK_EXISTED_DISTRICT = gql`
query District_getByCriteria($payload: QueryDistrictCriteriaInput) {
  District_getByCriteria(query: $payload) {
    ${DistrictData}
  }
}
`;

export const GET_ALL_LIST_DISTRICT = gql`
  query Districts_getAll($query: QueryDistrictsGetAllInput) {
    Districts_getAll(query: $query) {
      id
      code
      name
      city
      isActive
      lastModifiedAt
    }
  }
`;
