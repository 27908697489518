import { useBaseViewModel } from '@/common';
import { DistrictEntity } from '@/domains/district/entities';
import { FindDistrictUseCase } from '@/domains/district/usecases/find.usecase';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DistrictHttpRepository } from '../adapters/repositories';

export default function GetDetailDistrict() {
  const getDetailDistrict = new FindDistrictUseCase(new DistrictHttpRepository());
  const [currentQueryParameters] = useSearchParams();
  const getId = currentQueryParameters.get('id');

  const { loading: loadingDistrict, error: errorDistrict, catchAction } = useBaseViewModel();
  const [detailDistrict, setDetailDistrict] = useState<{
    data: DistrictEntity;
  }>({
    data: {
      id: '',
      name: '',
      code: '',
      dialCode: '',
      city: '',
      isActive: true,
    },
  });

  const actionGetDetail = () => {
    if (getId) {
      catchAction(async () => {
        const dataDetail = await getDetailDistrict.findById(getId);
        setDetailDistrict({ data: dataDetail });
      });
    }
  };

  return {
    loadingDistrict,
    errorDistrict,
    detailDistrict,
    actionGetDetail,
  };
}
