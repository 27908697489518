import { FindCountryUseCase } from '@/domains/countries/usecases/find.usecase';
import { CountriesHttpRepository } from '../adapters/repositories';
import { useSearchParams } from 'react-router-dom';
import { useBaseViewModel } from '@/common';
import { useState } from 'react';
import { CountriesEntity } from '@/domains/countries/entities';

export default function GetDetailCountry() {
  const getDetailCountry = new FindCountryUseCase(new CountriesHttpRepository());
  const [currentQueryParameters] = useSearchParams();
  const getId = currentQueryParameters.get('id');

  const { loading: loadingCountry, error: errorCountry, catchAction } = useBaseViewModel();
  const [detailCountry, setDetailCountry] = useState<{
    data: CountriesEntity;
  }>({
    data: {
      id: '',
      name: '',
      code: '',
      dialCode: '',
      isActive: true,
    },
  });

  const actionGetDetail = () => {
    if (getId) {
      catchAction(async () => {
        const dataDetail = await getDetailCountry.find(getId);
        setDetailCountry({ data: dataDetail });
      });
    }
  };

  return {
    loadingCountry,
    errorCountry,
    detailCountry,
    actionGetDetail,
  };
}
