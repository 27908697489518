// Generate code from clean architecture template

import { ListCity } from '../entities';
import { QueryCityPayload } from '../ports/payloads';
import { CityRepository } from '../ports/repositories';

export class GetListCityUsecase {
  constructor(private readonly repo: CityRepository) {}

  async run(payload?: QueryCityPayload): Promise<ListCity> {
    return this.repo.getListCity(payload);
  }
}
