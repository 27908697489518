import React, { useEffect, useState } from 'react';
import { useSelections } from 'ahooks';
import { useFormContext } from 'react-hook-form';
import _, { get, set } from 'lodash';
const useGetAllPermission = (
  data: any,
  keyData: string,
  nameApp: string,
  selectedPermissions: string[],
  listItemChecked: any,
  setListItemChecked: any,
) => {
  const permissionGroup = data[keyData];
  const permissionArrays = _.values(permissionGroup);
  const groupDisplayName = _.capitalize(_.startCase(keyData));

  const { selected, allSelected, isSelected, toggle, toggleAll } = useSelections(
    permissionArrays,
    selectedPermissions,
  );
  const [clicked, setClicked] = useState(false);
  const { getValues, setValue } = useFormContext();
  useEffect(() => {
    getValues('permissions');
  }, [toggleAll]);

  useEffect(() => {
    checkAll();
  }, [allSelected]);

  useEffect(() => {
    setValue('permissions', listItemChecked);
  }, [listItemChecked, selected]);

  const checkAll = () => {
    if (clicked) {
      const clone = [...listItemChecked];

      if (Array.isArray(clone)) {
        const index = clone.findIndex((item) => item.app == nameApp);
        if (index >= 0) {
          const data = get(clone[index], 'permissions');
          if (Array.isArray(data)) {
            let value = [...data].filter((item) => !permissionArrays.includes(item));
            if (allSelected) {
              value = data.concat(permissionArrays);
            }
            clone[index].permissions = value;
          }
          setListItemChecked(clone);
          setClicked(false);
        } else {
          const data = get(clone[clone.length + 1], 'permissions');
          if (Array.isArray(data)) {
            let value = [...data].filter((item) => !permissionArrays.includes(item));
            if (allSelected) {
              value = data.concat(permissionArrays);
            }
            set(clone[clone.length + 1], 'permissions', value);
          }
        }
      }
    }
  };

  return {
    groupDisplayName,
    allSelected,
    permissionArrays,
    nameApp,
    isSelected,
    toggle,
    setClicked,
    toggleAll,
  };
};

export default useGetAllPermission;
