import React, { useEffect, useState } from 'react';

const useWatchPermission = (
  watchPermissions: any,
  detailRole: any,
  showId: string | null,
  defaultValue: any,
  reset: any,
  openForm: any,
) => {
  useEffect(() => {
    if (!showId) {
      reset(defaultValue);
    }
  }, [openForm]);

  const [listItemChecked, setListItemChecked] = useState<
    { app: string; permissions: string[] }[] | undefined
  >(showId ? detailRole?.permissions : watchPermissions);

  useEffect(() => {
    if (showId) {
      setListItemChecked(detailRole?.permissions);
    } else {
      setListItemChecked(watchPermissions);
    }
  }, [showId]);

  const selectedPermissions = {
    selectedPermissions: showId ? detailRole?.permissions : [''],
    listItemChecked: listItemChecked,
    setListItemChecked,
  };

  return {
    selectedPermissions,
  };
};

export default useWatchPermission;
