import { useState } from 'react';
import { useBaseViewModel } from '@/common';
import { EmployeeEntity } from '@/domains/employee/entities';
import { FindEmployeeDomainUsecase } from '@/domains/employee/usecases';
import { EmployeeHttpRepository } from '../adapters/repositories';

const FindByIdEmployeeViewModel = () => {
  const { loading, error, catchAction } = useBaseViewModel();
  const [employee, setEmployee] = useState<EmployeeEntity>();

  const findEmployeeDomainUsecase = new FindEmployeeDomainUsecase(new EmployeeHttpRepository());

  const actionFetchEmployeeById = async (id: string) => {
    catchAction(async () => {
      const data = await findEmployeeDomainUsecase.findById(id);
      setEmployee(data);
    });
  };

  return {
    employee,
    loading,
    error,
    actionFetchEmployeeById,
  };
};

export default FindByIdEmployeeViewModel;
