import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import FormEmployeeViewmodel from '../viewmodels/form-employee.viewmodel';
import { EmployeeEntity } from '@/domains/employee/entities';
import { Button, Typography } from 'antd';
import CoreSelect from '@/components/Form/SelectAntForm';
import { useCoreContext } from '@/core';

interface Props {
  detail: EmployeeEntity;
}
export const FormEmployee: React.FC<Props> = ({ detail }) => {
  const { methodForm, onSubmit } = FormEmployeeViewmodel(detail);
  const { workShifts } = useCoreContext();

  const { control } = methodForm;

  useEffect(() => {
    methodForm.reset(detail);
  }, [detail?.id]);

  return (
    <FormProvider {...methodForm}>
      <form onSubmit={onSubmit}>
        <Typography.Title level={4}>Thông tin nhân viên</Typography.Title>
        <div className='flex gap-2'>
          <Typography className='w-40 text-lg font-medium'>Mã nhân viên: </Typography>
          <Typography className='text-base'>{detail.employeeCode}</Typography>
        </div>
        <div className='flex gap-2'>
          <Typography className='w-40 text-lg font-medium'>Họ tên: </Typography>
          <Typography className='text-base'>{detail.fullName}</Typography>
        </div>
        <div className='flex gap-2'>
          <Typography className='w-40 text-lg font-medium'>Loại hợp đồng: </Typography>
          <Typography className='text-base'>{detail.contract?.contractType}</Typography>
        </div>

        <div className='flex gap-2'>
          <Typography className='w-40 text-lg font-medium'>Ca làm việc: </Typography>
          <CoreSelect
            className='text-base w-60'
            control={control}
            name='workShift.id'
            optionsProps={workShifts}
            valueField='id'
            displayField='name'
            placeholder={'Chọn ca làm việc'}
          />
        </div>
        <div className='flex mt-2'>
          <Button type='primary' htmlType='submit'>
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
