import { PageProvider } from '@/core/context/PageContext';
import { useTableFilter } from '@/core/hooks/useTableFilter';
import React, { useEffect } from 'react';
import GetListWardViewModel from '../viewmodels/ward-list.viewmodel';
import GetAllListDistrictViewModel from '@/modules/district/viewmodels/district-get-all.viewmodel';

export const WardProvider = ({ children }: any) => {
  const { actionGetListWard, wardData, error, loading } = GetListWardViewModel();

  const { actionGetAllDistrict, districtData } = GetAllListDistrictViewModel();

  const { methodFilter } = useTableFilter({
    handleFetchData: actionGetListWard,
    querySearch: { search: '' },
    queryFilters: { isActive: undefined },
    queryPagination: { page: 1, limit: 10 },
  });

  const { control, setValue, reset, getValues, watch } = methodFilter;

  const searchDistrict = async (search: string) => {
    await actionGetAllDistrict({
      search,
      isActive: true,
    });
  };

  useEffect(() => {
    actionGetListWard(getValues());
    actionGetAllDistrict();
  }, []);

  const data = {
    wardData,
    actionGetListWard,
    searchDistrict,
    districtData,
    error,
    loading,
    control,
    setValue,
    reset,
    getValues,
    watch,
  };

  return <PageProvider {...data}>{children}</PageProvider>;
};
