import { PageProvider } from '@/core/context/PageContext';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import GetDetailCenter from '../viewmodels/center-detail.viewmodel';
import React from 'react';
import useViewModel from '../viewmodels/center-list.viewmodel';
import { useTableFilter } from '@/core/hooks/useTableFilter';
import GetListCountryViewModel from '@/modules/countries/viewmodels/countries-list.viewmodel';
import GetListCityViewModel from '@/modules/city/viewmodels/city-list.viewmodel';
import GetListDistrictViewModel from '@/modules/district/viewmodels/district-list.viewmodel';
import GetListWardViewModel from '@/modules/wards/viewmodels/ward-list.viewmodel';
import GetAllBusinessUnitViewModel from '@/modules/business-unit/viewmodels/business-unit-get-all.viewmodel';
import GetAllCityViewModel from '@/modules/city/viewmodels/city-get-all.viewmodel';
import GetAllListDistrictViewModel from '@/modules/district/viewmodels/district-get-all.viewmodel';
import GetAllWardViewModel from '@/modules/wards/viewmodels/ward-get-all.viewmodel';

export const CenterProvider = (props: any) => {
  const {
    detailCenter,
    actionGetDetailCenter,
    loading: loadingDetail,
    error: errorDetail,
  } = GetDetailCenter();

  const { centerData, actionGetAllCenter, loading, error } = useViewModel();
  const { countryData, actionGetListCountry } = GetListCountryViewModel();
  const { cityData, actionGetAllCity } = GetAllCityViewModel();
  const { districtData, actionGetAllDistrict } = GetAllListDistrictViewModel();
  const { wardData, actionGetAllWard } = GetAllWardViewModel();
  const { buData, actionGetAllBU } = GetAllBusinessUnitViewModel();

  const [currentQueryParameters] = useSearchParams();
  const getId = currentQueryParameters.get('id');

  const { methodFilter } = useTableFilter({
    handleFetchData: actionGetAllCenter,
    querySearch: { search: '' },
    queryFilters: { isActive: undefined },
    queryPagination: { page: 1, limit: 10 },
  });

  const { control, setValue, reset, getValues, watch } = methodFilter;

  useEffect(() => {
    actionGetAllCenter(getValues());
    actionGetListCountry();
    actionGetAllCity();
    actionGetAllDistrict();
    actionGetAllWard();
    actionGetAllBU();
  }, []);

  useEffect(() => {
    actionGetDetailCenter();
  }, [getId]);

  const data = {
    detailCenter,
    countryData,
    cityData,
    districtData,
    wardData,
    buData,
    actionGetAllDistrict,
    actionGetAllWard,
    actionGetListCountry,
    actionGetDetailCenter,
    loadingDetail,
    errorDetail,
    centerData,
    actionGetAllCenter,
    loading,
    error,
    control,
    setValue,
    reset,
    getValues,
    watch,
  };

  return <PageProvider {...data}>{props.children}</PageProvider>;
};
