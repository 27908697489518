// Generate code from clean architecture template

import { CenterTable } from '../entities';
import { QueryCenterPayload } from '../ports/payloads';
import { CenterRepository } from '../ports/repositories';

export class GetListCenterUsecase {
  constructor(private readonly repo: CenterRepository) {}

  async run(payload?: QueryCenterPayload): Promise<CenterTable[]> {
    const data = await this.repo.getListCenter(payload);
    return data;
  }
}
