import React from 'react';
import { useEffect } from 'react';
import { PageProvider } from '@/core/context/PageContext';
import { useTableFilter } from '@/core/hooks/useTableFilter';
import GetListCityViewModel from '../viewmodels/city-list.viewmodel';
import GetListCountryViewModel from '@/modules/countries/viewmodels/countries-list.viewmodel';

export const CityProvider = ({ children }: any) => {
  const { actionGetListCity, cityData, error, loading } = GetListCityViewModel();
  const { actionGetListCountry, countryData } = GetListCountryViewModel();

  const { methodFilter } = useTableFilter({
    handleFetchData: actionGetListCity,
    querySearch: { search: '' },
    queryFilters: { isActive: undefined },
    queryPagination: { page: 1, limit: 10 },
  });

  const { control, setValue, reset, getValues, watch } = methodFilter;

  const searchCountry = async (search: string) => {
    await actionGetListCountry({
      search,
      limit: 100,
      isActive: true,
    });
  };

  useEffect(() => {
    actionGetListCity(getValues());
    actionGetListCountry({ limit: 100, isActive: true });
  }, []);

  const data = {
    cityData,
    searchCountry,
    countryData,
    error,
    loading,
    control,
    setValue,
    reset,
    getValues,
    watch,
    actionGetListCity,
  };

  return <PageProvider {...data}>{children}</PageProvider>;
};
