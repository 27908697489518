export const EmployeeData = `
id
employeeCode
fullName
personalEmail
workEmail
phoneNumber
address
department
{
id
name
division{
    id
    name
}
}
position{
id
name
description
}

centre{
id
name
shortName

}
manager
status
hireDate
attendanceMethod
startWorkingHour
{
hour
minute
}
endWorkingHour {
hour
minute
}
contract { 
    contractType
    onboardingDate
}
workShift{
id
name
code
startWorkingHour {
    hour
    minute
    }

endWorkingHour { 
    hour
    minute
 }
startBreakHour { 
    hour
    minute
 }
endBreakHour { 
    hour
    minute
 }
 appliedDays
 appliedDepartments
 allowedLateArriveMinutes
 allowedEarlyLeaveMinutes
 isDefault
 isActive
}
`;
