import { CoreInput } from '@/components/Form/InputAntForm';
import CoreSelect from '@/components/Form/SelectAntForm';
import { useCoreContext } from '@/core';
import { City, District } from '@/domains/center/entities';
import { Typography } from 'antd';
import { get } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  className: string;
  showId?: any;
  detailCenter: any;
}

export const AddressInput = (props: Props) => {
  const { className, showId, detailCenter } = props;
  const { control, watch, setValue } = useFormContext();
  const { countryData, cityData, districtData, wardData, actionGetAllDistrict, actionGetAllWard } =
    useCoreContext();
  const watchCountry = watch('address.countryCode');
  const watchCity = watch('address.cityCode');
  const watchDistrict = watch('address.districtCode');
  const watchWard = watch('address.wardCode');
  useEffect(() => {
    if (watchCity !== '') {
      const findIdCity =
        Array.isArray(cityData) &&
        cityData.length > 0 &&
        cityData.find((item: City) => item.code === watchCity);
      actionGetAllDistrict({ city: get(findIdCity, 'id') });
      if (showId === null || watchCity !== get(detailCenter, 'address.cityCode')) {
        setValue('address.districtCode', '');
        setValue('address.wardCode', '');
        setValue('address.address', '');
      }
    }
  }, [watchCity, detailCenter, cityData]);

  useEffect(() => {
    if (watchDistrict !== '' || watchDistrict !== undefined) {
      const findIdDistrict =
        Array.isArray(districtData) &&
        districtData.length > 0 &&
        districtData.find((item: District) => item.code === watchDistrict);
      actionGetAllWard({ district: get(findIdDistrict, 'id') });
      if (showId === null || watchDistrict !== get(detailCenter, 'address.districtCode')) {
        setValue('address.wardCode', '');
        setValue('address.address', '');
      }
    }
  }, [watchDistrict, detailCenter, districtData]);
  useEffect(() => {
    if (showId === null || watchWard !== get(detailCenter, 'address.wardCode')) {
      setValue('address.address', '');
    }
    if (!watchWard) {
      setValue('address.wardCode', '');
    }
  }, [watchWard]);

  useEffect(() => {
    if (showId === null || watchCountry !== get(detailCenter, 'address.countryCode')) {
      setValue('address.wardCode', '');
      setValue('address.districtCode', '');
      setValue('address.cityCode', '');
      setValue('address.address', '');
    }
    if (!watchCountry) {
      setValue('address.countryCode', '');
    }
  }, [watchCountry]);

  useEffect(() => {
    if (!watchDistrict) {
      setValue('address.districtCode', '');
    }
  }, [watchDistrict]);

  useEffect(() => {
    if (!watchCity) {
      setValue('address.cityCode', '');
    }
  }, [watchCity]);

  const renderContent = useCallback(() => {
    if (watchCountry === 'VN') {
      return (
        <>
          <CoreSelect
            control={control}
            name='address.cityCode'
            optionsProps={cityData ?? []}
            displayField='name'
            valueField='code'
            className='w-1/6'
            showSearch
            filterOption={(inputValue, option) => {
              if (option) {
                return String(get(option, 'label'))
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
              }
              return true;
            }}
          />
          <CoreSelect
            control={control}
            name='address.districtCode'
            optionsProps={districtData ?? []}
            displayField='name'
            valueField='code'
            className='w-1/6'
            defaultValue={''}
            showSearch
            filterOption={(inputValue, option) => {
              if (option) {
                return String(get(option, 'label'))
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
              }
              return true;
            }}
          />
          <CoreSelect
            control={control}
            name='address.wardCode'
            optionsProps={wardData ?? []}
            displayField='name'
            valueField='code'
            className='w-1/6'
            showSearch
            filterOption={(inputValue, option) => {
              if (option) {
                return String(get(option, 'label'))
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
              }
              return true;
            }}
          />
        </>
      );
    }
    return (
      <>
        <CoreInput control={control} name='address.city' className='w-1/6' />
        <CoreInput control={control} name='address.district' className='w-1/6' />
        <CoreInput control={control} name='address.ward' className='w-1/6' />
      </>
    );
  }, [watchCountry, cityData, districtData, wardData]);

  return (
    <div className={className}>
      <Typography>Country / City / District / Address</Typography>
      <div className='w-full flex flex-nowrap gap-[8px] mt-3'>
        <CoreSelect
          control={control}
          name='address.countryCode'
          optionsProps={get(countryData, 'data', [])}
          displayField='name'
          valueField='code'
          className='w-1/6'
          showSearch
          filterOption={(inputValue, option) => {
            if (option) {
              return String(get(option, 'label')).toLowerCase().includes(inputValue.toLowerCase());
            }
            return true;
          }}
        />
        {renderContent()}
        <CoreInput control={control} name='address.address' className='w-1/3' />
      </div>
    </div>
  );
};
