import { useBaseViewModel } from '@/common';
import { DeleteRoleUseCase } from '@/domain/use-cases/roles/delete';
import { RolesRepoIml } from '../adapters/repositories';

export default function DeleteMenuItem() {
  const deleteRole = new DeleteRoleUseCase(new RolesRepoIml());

  const { loading, error, catchAction } = useBaseViewModel();

  const actionDelete = (id: string) => {
    catchAction(async () => {
      await deleteRole.delete(id);
    });
  };

  return {
    loading,
    error,
    actionDelete,
  };
}
