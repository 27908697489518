import { WardEntity } from '../entities';
import { WardRepository } from '../ports/repositories';

export class FindWardUseCase {
  constructor(private readonly repository: WardRepository) {}

  async findById(id: string): Promise<WardEntity> {
    const data = this.repository.getDetailWard(id);
    return data;
  }
}
