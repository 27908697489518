import { useState } from 'react';
import { CountriesHttpRepository } from '../adapters/repositories';
import { ListCountry } from '@/domains/countries/entities';
import { useBaseViewModel } from '@/common';
import { QueryCountriesPayload } from '@/domains/countries/ports/payloads';
import { GetListCountriesUsecase } from '@/domains/countries/usecases';

export default function GetListCountryViewModel() {
  const getListCountriesUsecase = new GetListCountriesUsecase(new CountriesHttpRepository());

  const { loading, error, catchAction } = useBaseViewModel();
  const [countryData, setCountryData] = useState<ListCountry>();

  const actionGetListCountry = async (payload?: QueryCountriesPayload) => {
    await catchAction(async () => {
      const result = await getListCountriesUsecase.run(payload);
      setCountryData(result);
    });
  };

  return {
    loading,
    error,
    countryData,
    setCountryData,
    actionGetListCountry,
  };
}
