import { FindAccountPayload } from '@/domain/ports/account/payloads';
import { AccountsRepository } from '@/domain/ports/account/repositories';

export class AccountsListUseCase {
  constructor(private readonly repository: AccountsRepository) {}

  async run(payload?: FindAccountPayload) {
    const { data } = await this.repository.get(payload);
    return { data };
  }
}
