import React from 'react';
import { useEffect } from 'react';
import GetListCountryViewModel from '../viewmodels/countries-list.viewmodel';
import { PageProvider } from '@/core/context/PageContext';
import { useTableFilter } from '@/core/hooks/useTableFilter';

export const CountryProvider = ({ children }: any) => {
  const { actionGetListCountry, countryData, error, loading } = GetListCountryViewModel();

  const { methodFilter } = useTableFilter({
    handleFetchData: actionGetListCountry,
    querySearch: { search: '' },
    queryFilters: { isActive: undefined },
    queryPagination: { page: 1, limit: 10 },
  });

  const { control, setValue, reset, getValues, watch } = methodFilter;

  useEffect(() => {
    actionGetListCountry(getValues());
  }, []);

  const data = {
    countryData,
    actionGetListCountry,
    error,
    loading,
    control,
    setValue,
    reset,
    getValues,
    watch,
  };

  return <PageProvider {...data}>{children}</PageProvider>;
};
