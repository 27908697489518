import { CoreFilterActive } from '@/components/InputFilter/CoreFilterActive';
import { CoreInputSearch } from '@/components/InputFilter/CoreInputSearch';
import { useCoreContext } from '@/core';
import { Button } from 'antd';
import React from 'react';

interface Props {
  handleCreate(): void;
}

const RolesTableFilter = (props: Props) => {
  const { handleCreate } = props;
  const { methodFilter } = useCoreContext();
  const { control } = methodFilter;

  return (
    <form className='w-full flex items-center gap-10 mb-10'>
      <CoreInputSearch control={control} className='w-64' />
      <CoreFilterActive control={control} className='w-32' />
      <div className='ml-auto mr-4'>
        <Button size='large' className='bg-blue-500 text-white' onClick={() => handleCreate()}>
          + Create
        </Button>
      </div>
    </form>
  );
};

export default RolesTableFilter;
