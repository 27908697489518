export const UserData = `
id
firstName
lastName
roles {
  id
  name
  isActive
  permissions{
    app
    permissions
  }
}
email
isActive
`;
