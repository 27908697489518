import { CoreInput } from '@/components/Form/InputAntForm';
import { AccountsEntity } from '@/domain/entities/account';
import { Button, Typography } from 'antd';
import React, { useEffect } from 'react';
import { RolesSelect } from '../../components/RolesSelect';
import useViewModel from '../../viewmodels/form';

interface Props {
  detailAccount: AccountsEntity;
  openForm: boolean;
  showId: string | null;
}

export const AccountForm = (props: Props) => {
  const { openForm, showId } = props;
  const { methodForm, onSubmit, defaultValue } = useViewModel(props);

  const {
    control,
    reset,
    formState: { isSubmitting, isDirty },
  } = methodForm;

  useEffect(() => {
    if (!showId) {
      reset(defaultValue);
    }
  }, [openForm]);

  return (
    <form onSubmit={onSubmit}>
      <div className='w-full flex flex-wrap'>
        <Typography.Text className='w-full'>Name </Typography.Text>
        <CoreInput control={control} name='name' className='w-full mb-10' disabled />
        <Typography.Text className='w-full'>Email </Typography.Text>
        <CoreInput control={control} name='email' className='w-full mb-10' disabled />
        <Typography.Text className='w-full'>Roles (*)</Typography.Text>
        <RolesSelect control={control} name='roles' className='w-full mb-10' openForm={openForm} />
      </div>
      <div className='text-right'>
        <Button type='primary' htmlType='submit' loading={isSubmitting} disabled={!isDirty}>
          Save
        </Button>
      </div>
    </form>
  );
};
