import { CoreFilterActive } from '@/components/InputFilter/CoreFilterActive';
import { CoreInputSearch } from '@/components/InputFilter/CoreInputSearch';
import { useCoreContext } from '@/core';
import { RolesSelect } from '../../components/RolesSelect';
import React from 'react';

const AccountsTableFilter = () => {
  const { methodFilter } = useCoreContext();
  const { control } = methodFilter;

  return (
    <form className='w-full flex flex-wrap mb-10'>
      <CoreInputSearch control={control} className='w-64 px-6' />
      <CoreFilterActive control={control} className='w-32' />
      <RolesSelect
        control={control}
        name='roles'
        openForm={true}
        label='Roles'
        className='w-48 px-6'
      />
    </form>
  );
};

export default AccountsTableFilter;
