import { useRef } from 'react';
import { get } from 'lodash';
import { PropsCityForm } from '@/domains/city/ports/payloads';
import { CityHttpRepository } from '../adapters/repositories';
import { CheckExistedCityByCodeUsecase } from '@/domains/city/usecases/check-existed';

export default function CheckExistCityByCode(props: PropsCityForm) {
  const checkExistedByCodeUC = new CheckExistedCityByCodeUsecase(new CityHttpRepository());
  const abortControllerCode = useRef(new AbortController());
  const currentValue = useRef<string | undefined>(get(props, 'detailCity.code'));

  const validateCode = async (value: string | undefined) => {
    if (value && value !== currentValue.current) {
      abortControllerCode.current.abort();
      abortControllerCode.current = new AbortController();
      const data = await checkExistedByCodeUC.run(value, abortControllerCode);
      if (data !== null) {
        return false;
      }
      return true;
    }
    return true;
  };

  return {
    validateCode,
  };
}
