// Generate code from clean architecture template

import { ImportEmployeePayload } from '../ports/payloads';
import { EmployeeRepository } from '../ports/repositories';

export class ImportEmployeeUsecase {
  constructor(private readonly repo: EmployeeRepository) {}

  async run(payload: ImportEmployeePayload): Promise<{ id: string }> {
    return await this.repo.importEmployee(payload);
  }
}
