import { BusinessUnitEntity } from '@/domains/business-unit/entities';
import { BusinessUnitRepository } from '@/domains/business-unit/ports/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { GET_ALL_BU } from '../../graphql';

export class BusinessUnitHttpRepository implements BusinessUnitRepository {
  async getAllBU(): Promise<BusinessUnitEntity[]> {
    const response: BusinessUnitEntity[] = await HttpClientAdapter.query<BusinessUnitEntity[]>({
      query: GET_ALL_BU,
      dataKey: 'BusinessUnit_getAll',
    });
    return response;
  }
}
