import { PropsCityForm } from '@/domains/city/ports/payloads';
import * as yup from 'yup';
import CheckExistCityByCode from '../viewmodels/check-exist-by-code.viewmodel';

export const validateCity = (props: PropsCityForm) => {
  const { validateCode } = CheckExistCityByCode(props);

  const schemaCity = yup.object().shape({
    name: yup.string().required('Name is empty').trim(),
    code: yup
      .string()
      .required('Code is empty')
      .trim()
      .test('Code exist', 'Code has already existed', validateCode),
  });

  return { schemaCity };
};
