import { CenterEntity } from '../entities';
import { CenterRepository } from '../ports/repositories';

export class FindCenterByUseCase {
  constructor(private readonly repository: CenterRepository) {}

  async find(payload: string): Promise<CenterEntity> {
    const data = await this.repository.getDetailCenter(payload);

    return data;
  }
}
