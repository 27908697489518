import { WardEntity } from '../entities';
import { WardRepository } from '../ports/repositories';

export class CheckExistedWardByCodeUsecase {
  constructor(private readonly repo: WardRepository) {}

  async run(code: string, abort: any): Promise<WardEntity | null> {
    const data = await this.repo.checkExistWardByCode(code, abort);
    return data;
  }
}
