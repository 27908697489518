// Generate code from clean architecture template

import { FindResult } from '@/common';
import { ImportEmployeeEntity } from '../entities';
import { QueryImportEmployeePayload } from '../ports/payloads';
import { ImportEmployeeRepository } from '../ports/repositories';

export class FindPaginateImportEmployeeUsecase {
  constructor(private readonly repo: ImportEmployeeRepository) {}

  async run(payload?: QueryImportEmployeePayload): Promise<FindResult<ImportEmployeeEntity>> {
    return await this.repo.findPaginate(payload);
  }
}
