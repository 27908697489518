import { useBaseViewModel } from '@/common';
import { RolesEntity } from '@/domain/entities/roles';
import { FindRolesPayload } from '@/domain/ports/roles/payloads';
import { RolesListUseCase } from '@/domain/use-cases/roles';
import { useState } from 'react';
import { RolesRepoIml } from '../adapters/repositories';

export default function GetListRole() {
  const getListRole = new RolesListUseCase(new RolesRepoIml());

  const { loading, error, catchAction } = useBaseViewModel();
  const [pageData, setPageData] = useState<{ dataList: RolesEntity[] }>({ dataList: [] });

  const actionGetAll = async (payload?: FindRolesPayload) => {
    await catchAction(async () => {
      const { data } = await getListRole.run(payload);
      setPageData({ dataList: data });
    });
  };

  return {
    loading,
    error,
    pageData,
    actionGetAll,
  };
}
