// Generate code from clean architecture template

import { FindResult } from '@/common';
import { EmployeeEntity } from '../entities';
import { QueryEmployeePayload } from '../ports/payloads';
import { EmployeeRepository } from '../ports/repositories';

export class FindAllEmployeeUsecase {
  constructor(private readonly repo: EmployeeRepository) {}

  async run(payload: QueryEmployeePayload): Promise<FindResult<EmployeeEntity>> {
    return await this.repo.findAll(payload);
  }
}
