import { CoreInput } from '@/components/Form/InputAntForm';
import { useCoreContext } from '@/core';
import { Button } from 'antd';

import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const EmployeeTableFilter = () => {
  const { methodFilter, handleClickCreate, actionGetListImportEmployee } = useCoreContext();
  const { control } = methodFilter;
  const [searchParams] = useSearchParams();
  const type = searchParams.get('action');
  useEffect(() => {
    if (type === 'Create') actionGetListImportEmployee();
  }, [type]);

  return (
    <div className='flex justify-center mb-2'>
      <CoreInput control={control} name='textSearch' className='w-64' />
      <div className='ml-auto mr-4'>
        <Button
          type='primary'
          onClick={async () => {
            handleClickCreate();
          }}
        >
          Import
        </Button>

        {/* <UploadAttachment
          onFinish={handleImport}
          accept='.xlsx'
          setLoading={() => {
            //
          }}
        /> */}
      </div>
    </div>
  );
};

export default EmployeeTableFilter;
