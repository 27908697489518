import { CountriesEntity } from '../entities';
import { CountriesRepository } from '../ports/repositories';

export class CheckExistedCountryByCodeUsecase {
  constructor(private readonly repo: CountriesRepository) {}

  async run(code: string, abort: any): Promise<CountriesEntity | null> {
    const data = await this.repo.checkExistCountryByCode(code, abort);
    return data;
  }
}
