import { FindResult } from '@/common';
import { ImportEmployeeEntity } from '@/domains/import-employee/entities';
import { QueryImportEmployeePayload } from '@/domains/import-employee/ports/payloads';
import { ImportEmployeeRepository } from '@/domains/import-employee/ports/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { FIND_DETAIL_IMPORT_EMPLOYEE, FIND_PAGINATE_IMPORT_EMPLOYEE } from '../../graphql';

export class ImportEmployeeHttpRepository implements ImportEmployeeRepository {
  async findDetail(id: string): Promise<ImportEmployeeEntity> {
    console.log('id', id);
    const response: ImportEmployeeEntity = await HttpClientAdapter.query<ImportEmployeeEntity>({
      query: FIND_DETAIL_IMPORT_EMPLOYEE,
      dataKey: 'ImportEmployee_findDetail',
      variables: {
        id,
      },
    });
    return response;
  }
  async findPaginate(
    payload: QueryImportEmployeePayload,
  ): Promise<FindResult<ImportEmployeeEntity>> {
    const response: FindResult<ImportEmployeeEntity> = await HttpClientAdapter.query<
      FindResult<ImportEmployeeEntity>
    >({
      query: FIND_PAGINATE_IMPORT_EMPLOYEE,
      dataKey: 'ImportEmployee_getList',
      variables: {
        input: {
          ...payload,
        },
      },
    });
    return response;
  }
}
