import { useBaseViewModel } from '@/common';
import { WardEntity } from '@/domains/wards/entities';
import { QueryWardPayload } from '@/domains/wards/ports/payloads';
import { GetAllWardUsecase } from '@/domains/wards/usecases/get-all.usecase';
import { useState } from 'react';
import { WardHttpRepository } from '../adapters/repositories';

export default function GetAllWardViewModel() {
  const getAllWardUsecase = new GetAllWardUsecase(new WardHttpRepository());

  const { loading, error, catchAction } = useBaseViewModel();
  const [wardData, setWardData] = useState<WardEntity[]>();

  const actionGetAllWard = async (payload?: QueryWardPayload) => {
    await catchAction(async () => {
      const result = await getAllWardUsecase.run(payload);
      setWardData(result);
    });
  };

  return {
    loading,
    error,
    wardData,
    actionGetAllWard,
  };
}
