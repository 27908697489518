import React from 'react';
import dayjs from 'dayjs';

export const TimeCell = (props: any) => {
  const { value } = props;
  if (!value) {
    return <span>N/A</span>;
  }

  return <span>{dayjs(Number(value)).format('DD/MM/YYYY HH:mm:ss')}</span>;
};
