import { gql } from '@apollo/client';
import { RolesData } from './roles-data';

export const GET_ROLES_ITEMS = gql`
  query Role_getList($query: QueryRoleInput) {
    Role_getList(query: $query) {
      data {
        ${RolesData}
      }
      count
      limit
      page
    }
  }
`;

export const GET_ROLES_ALLS = gql`
  query Role_getAll {
    Role_getAll {
      ${RolesData}
    }
  }
`;

export const CREATE_ROLES = gql`
mutation Role_create($payload: CreateRoleInput!) {
  Role_create(createRoleInput: $payload) {
    ${RolesData}
  }
}
`;

export const UPDATE_ROLES = gql`
mutation Role_update($payload: UpdateRoleInput!) {
  Role_update(updateRoleInput: $payload) {
    ${RolesData}
  }
}
`;

export const FIND_ROLES = gql`
query Role_getById($id: String!) {
  Role_getById(id: $id) {
    ${RolesData}
  }
}
`;

export const DELETE_ROLES = gql`
  mutation Role_delete($id: String!) {
    Role_delete(id: $id) {
    ${RolesData}
  }
}
`;
