import { useBaseViewModel } from '@/common';
import { WorkShift } from '@/domains/employee/entities';
import { FindEmployeeDomainUsecase } from '@/domains/employee/usecases';
import { useState } from 'react';
import { EmployeeHttpRepository } from '../adapters/repositories';
import { FindWorkShiftPayload } from '@/domains/employee/ports/payloads';

export default function FindWorkShiftViewModel() {
  const { loading, error, catchAction } = useBaseViewModel();
  const [workShifts, setWorkShifts] = useState<WorkShift[]>();

  const findEmployeeDomainUsecase = new FindEmployeeDomainUsecase(new EmployeeHttpRepository());

  const actionFetchWorkShift = async (payload?: FindWorkShiftPayload) => {
    catchAction(async () => {
      const data = await findEmployeeDomainUsecase.findWorkShift(payload);
      setWorkShifts(data);
    });
  };

  return {
    workShifts,
    loading,
    error,
    actionFetchWorkShift,
  };
}
