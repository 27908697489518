import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { Authorize } from '@/core';
import { Button } from 'antd';
import React from 'react';
import { CityProvider } from '../context';
import { DetailCity } from './Form';
import { CityTable } from './List/CityTable';

export const CityScreen: React.FC = () => {
  const { open, handleClose, setOpen, handleClickCreate } = useHandleActionItem();

  return (
    <CityProvider>
      <div className='bg-white py-4 px-4 flex flex-col flex-wrap'>
        <div className='ml-auto'>
          <Button
            type='primary'
            size='middle'
            onClick={() => handleClickCreate()}
            className='rounded'
          >
            + Create
          </Button>
        </div>
        <div className='w-full mt-2'>
          <CityTable />
          <DetailCity open={open} handleClose={handleClose} setOpen={setOpen} />
        </div>
      </div>
    </CityProvider>
  );
};

export const CityList = Authorize(CityScreen);
