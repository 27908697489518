import React from 'react';
import { CountryProvider } from '../context';
import { Authorize } from '@/core';
import { CountryTable } from './List/CountryTable';
import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { Button } from 'antd';
import { DetailCountry } from './Form';

export const CountryScreen: React.FC = () => {
  const { open, handleClose, setOpen, handleClickCreate } = useHandleActionItem();

  return (
    <CountryProvider>
      <div className='bg-white py-4 px-4 flex flex-col flex-wrap'>
        <div className='ml-auto'>
          <Button
            type='primary'
            size='middle'
            onClick={() => handleClickCreate()}
            className='rounded'
          >
            + Create
          </Button>
        </div>
        <div className='w-full mt-2'>
          <CountryTable />
          <DetailCountry open={open} handleClose={handleClose} setOpen={setOpen} />
        </div>
      </div>
    </CountryProvider>
  );
};

export const CountryList = Authorize(CountryScreen);
