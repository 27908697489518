import { useBaseViewModel } from '@/common';
import { PermissionEntity } from '@/domain/entities/permission';
import { AllPermissionUseCase } from '@/domain/use-cases/permission/getAll';
import { useState } from 'react';
import { PermissionRepoIml } from '../adapters/repositories';

export default function GetListPermission() {
  const getAllPermission = new AllPermissionUseCase(new PermissionRepoIml());

  const {
    loading: loadingListPermission,
    error: errorListPermission,
    catchAction,
  } = useBaseViewModel();
  const [listPermission, setListPermission] = useState<{
    Permission_getAll: PermissionEntity[] | [];
  }>({
    Permission_getAll: [],
  });

  const actionGetListPermission = async () => {
    await catchAction(async () => {
      const data = await getAllPermission.run();
      setListPermission(data);
    });
  };

  return {
    loadingListPermission,
    errorListPermission,
    listPermission,
    actionGetListPermission,
  };
}
