import { PropsDistrictForm } from '@/domains/district/ports/payloads';
import * as yup from 'yup';
import CheckExistDistrictByCode from '../viewmodels/check-exist-by-code.viewmodel';

export const validateDistrict = (props: PropsDistrictForm) => {
  const { validateCode } = CheckExistDistrictByCode(props);

  const schemaDistrict = yup.object().shape({
    name: yup.string().required('Name is empty').trim(),
    code: yup
      .string()
      .required('Code is empty')
      .trim()
      .test('Code exist', 'Code has already existed', validateCode),
  });

  return { schemaDistrict };
};
