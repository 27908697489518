import CoreTable from '@/components/Table';
import { Typography } from 'antd';
import React from 'react';
import ImportEmployeeTableColumns from '../../components/ImportTableColums';
import { get } from 'lodash';
import { useCoreContext } from '@/core';
import { CorePagination } from '@/components/Pagination';
import ImportDetailEmployeeTableColumns from '../../components/ImportDetailColumns';
import { ImportEmployeeRowResult } from '@/domains/import-employee/entities';

const ImportDetailTable = ({
  dataImportEmployee,
}: {
  dataImportEmployee: ImportEmployeeRowResult[];
}) => {
  const { loading } = useCoreContext();

  const tableColumns = ImportDetailEmployeeTableColumns();
  return (
    <CoreTable
      columns={tableColumns}
      dataSource={dataImportEmployee}
      tableName='Center'
      loading={loading}
    />
  );
};

export default ImportDetailTable;
