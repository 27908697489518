import { useBaseViewModel } from '@/common';
import { ListCity } from '@/domains/city/entities';
import { QueryCityPayload } from '@/domains/city/ports/payloads';
import { GetListCityUsecase } from '@/domains/city/usecases';
import { useState } from 'react';
import { CityHttpRepository } from '../adapters/repositories';

export default function GetListCityViewModel() {
  const getListCityUsecase = new GetListCityUsecase(new CityHttpRepository());

  const { loading, error, catchAction } = useBaseViewModel();
  const [cityData, setCityData] = useState<ListCity>();

  const actionGetListCity = async (payload?: QueryCityPayload) => {
    await catchAction(async () => {
      const result = await getListCityUsecase.run(payload);
      setCityData(result);
    });
  };

  return {
    loading,
    error,
    cityData,
    actionGetListCity,
  };
}
