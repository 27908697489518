import { AccountProvider } from '../context';
import React from 'react';
import { AccountTable } from './List/AccountTable';
import { Detail } from './Form';
import { Authorize } from '@/core';

export const AccountListScreen: React.FC<unknown> = () => {
  return (
    <>
      <AccountProvider>
        <div className='bg-white py-10 flex flex-col flex-wrap'>
          <div>
            <AccountTable />
            <Detail />
          </div>
        </div>
      </AccountProvider>
    </>
  );
};

export const AccountList = Authorize<unknown>(AccountListScreen);
