import React from 'react';

import { useSSOAuthentication } from '@/core/hooks';
import { Spin, FullView } from '@/components';

import './index.css';

export function Authorize<T>(Component: React.FC<T>): React.FC<T> {
  // eslint-disable-next-line react/display-name
  return (props: any) => {
    const { isAuthenticated } = useSSOAuthentication(true);
    if (isAuthenticated) {
      return <Component {...props} />;
    }
    return (
      <FullView className='container-center'>
        <Spin />
      </FullView>
    );
  };
}
