import * as yup from 'yup';
import { PropsWardForm } from '@/domains/wards/ports/payloads';
import CheckExistWardByCode from '../viewmodels/check-exist-by-code.viewmodel';

export const validateWard = (props: PropsWardForm) => {
  const { validateCode } = CheckExistWardByCode(props);

  const schemaWard = yup.object().shape({
    prefix: yup.string().required('Prefix is empty').trim(),
    name: yup.string().required('Name is empty').trim(),
    code: yup
      .string()
      .required('Code is empty')
      .trim()
      .test('Code exist', 'Code has already existed', validateCode),
  });

  return { schemaWard };
};
