// Generate code from clean architecture template

import { FindResult, useBaseViewModel } from '@/common';
import { ImportEmployeeEntity } from '@/domains/import-employee/entities';
import {
  FindDetailImportEmployeeUsecase,
  FindPaginateImportEmployeeUsecase,
} from '@/domains/import-employee/usecases';
import { QueryEmployeePayload } from '@domains/employee/ports/payloads';
import { useState } from 'react';
import { ImportEmployeeHttpRepository } from '../adapters/repositories/import-employee.http.repository';

export default function ImportEmployeeViewModel() {
  const [dataListImportEmployee, setDataListImportEmployee] =
    useState<FindResult<ImportEmployeeEntity>>();
  const [dataImportEmployee, setDataImportEmployee] = useState<ImportEmployeeEntity>();
  const { loading, error, catchAction } = useBaseViewModel();

  const repo = new ImportEmployeeHttpRepository();
  const findPaginateUC = new FindPaginateImportEmployeeUsecase(repo);
  const findOneUC = new FindDetailImportEmployeeUsecase(repo);

  const actionGetListImportEmployee = async (payload?: QueryEmployeePayload) => {
    catchAction(async () => {
      const data = await findPaginateUC.run(payload);
      setDataListImportEmployee(data);
    });
  };
  const actionGetDetailEmployee = async (id: string) => {
    catchAction(async () => {
      const result = await findOneUC.run(id);
      setDataImportEmployee(result);
    });
  };

  return {
    actionGetListImportEmployee,
    dataListImportEmployee,
    loading,
    error,
    actionGetDetailEmployee,
    dataImportEmployee,
  };
}
