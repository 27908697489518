// Generate code from clean architecture template

import { CountriesEntity, ListCountry } from '@/domains/countries/entities';
import { CountriesPayloadForm, QueryCountriesPayload } from '@/domains/countries/ports/payloads';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { CountriesRepository } from '@domains/countries/ports/repositories';
import {
  CHECK_EXISTED_COUNTRY,
  CREATE_COUNTRY,
  GET_DETAIL_COUNTRY,
  GET_LIST_COUNTRY,
  UPDATE_COUNTRY,
} from '../../graphql';

export class CountriesHttpRepository implements CountriesRepository {
  async createCountry(payload: CountriesPayloadForm): Promise<CountriesEntity> {
    const response: CountriesEntity = await HttpClientAdapter.mutate<CountriesEntity>({
      mutation: CREATE_COUNTRY,
      variables: { payload: { ...payload } },
    });
    return response;
  }

  async updateCountry(payload: CountriesPayloadForm): Promise<CountriesEntity> {
    const response: CountriesEntity = await HttpClientAdapter.mutate<CountriesEntity>({
      mutation: UPDATE_COUNTRY,
      variables: { payload: { ...payload } },
    });
    return response;
  }

  async getListCountry(payload?: QueryCountriesPayload): Promise<ListCountry> {
    const response: ListCountry = await HttpClientAdapter.query<ListCountry>({
      query: GET_LIST_COUNTRY,
      dataKey: 'Countries_getList',
      variables: { query: { sortBy: ['lastModifiedAt|desc', '_id|desc'], ...payload } },
    });
    return response;
  }

  async getDetailCountry(id: string): Promise<CountriesEntity> {
    const response: CountriesEntity = await HttpClientAdapter.query<CountriesEntity>({
      query: GET_DETAIL_COUNTRY,
      dataKey: 'Country_getById',
      variables: { id },
    });
    return response;
  }

  async checkExistCountryByCode(code: string, aborterRef: any): Promise<CountriesEntity | null> {
    try {
      const response: CountriesEntity = await HttpClientAdapter.query<CountriesEntity>({
        query: CHECK_EXISTED_COUNTRY,
        variables: { payload: { code } },
        fetchPolicy: 'network-only',
        context: {
          fetchOptions: {
            signal: aborterRef.current.signal,
          },
        },
        dataKey: 'Country_getByCriteria',
      });
      return response;
    } catch (error) {
      return null;
    }
  }
}
