import { CountriesEntity } from '../entities';
import { CountriesRepository } from '../ports/repositories';

export class FindCountryUseCase {
  constructor(private readonly repository: CountriesRepository) {}

  async find(id: string): Promise<CountriesEntity> {
    const data = this.repository.getDetailCountry(id);
    return data;
  }
}
