import { gql } from '@apollo/client';
import { WardData } from './ward';

export const GET_LIST_WARD = gql`
query Wards_getList($query: QueryWardsInput) {
  Wards_getList(query: $query) {
    data {
      ${WardData}
    }
    limit
    count
    page
  }
}
`;

export const CREATE_WARD = gql`
mutation Ward_create($payload: CreateWardInput!) {
  Ward_create(createWardInput: $payload) {
    ${WardData}
  }
}
`;

export const UPDATE_WARD = gql`
mutation Ward_update($payload: UpdateWardInput!) {
  Ward_update(updateWardInput: $payload) {
    ${WardData}
  }
}
`;

export const GET_DETAIL_WARD = gql`
query Ward_getById($id: String!) {
  Ward_getById(id: $id) {
    ${WardData}
  }
}
`;

export const GET_ALL_WARD = gql`
query Ward_getAll($payload: QueryWardsGetAllInput) {
  Ward_getAll(query: $payload) {
    ${WardData}
  }
}
`;

export const CHECK_EXISTED_WARD = gql`
query Ward_getByCriteria($payload: QueryWardCriteriaInput) {
  Ward_getByCriteria(query: $payload) {
    ${WardData}
  }
}
`;
