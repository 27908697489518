import CoreTable from '@/components/Table';
import { useCoreContext } from '@/core';
import React from 'react';
import { CenterTableColumns } from '../../components/TableColumn';
import { get } from 'lodash';
import { Typography } from 'antd';
import { CorePagination } from '@/components/Pagination';

export const CenterTable = () => {
  const { loading, error, centerData, setValue, getValues } = useCoreContext();
  const tableColumns = CenterTableColumns();

  return (
    <>
      {error && <div>Error</div>}
      {!error && (
        <div>
          <CoreTable
            columns={tableColumns}
            dataSource={get(centerData, 'data') ?? []}
            tableName='Center'
            loading={loading}
          />
          <div className='flex mt-4 justify-between'>
            <Typography.Title level={3}>
              Total: {get(centerData, 'count') > 999 ? '>= 1000' : get(centerData, 'count')}
            </Typography.Title>
            <CorePagination
              total={get(centerData, 'count') ?? 0}
              defaultPageSize={10}
              showSizeChanger
              pageSizeOptions={['5', '10', '20']}
              onChange={(page, pageSize) => {
                setValue('page', page);
                setValue('limit', pageSize);
              }}
              current={getValues('page')}
            />
          </div>
        </div>
      )}
    </>
  );
};
