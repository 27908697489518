import { gql } from '@apollo/client';
import { MenuItemData } from './menu-item-data';

export const GET_MENU_ITEMS = gql`
query MenuItem_getAll($query: QueryMenuItemInput) {
  MenuItem_getAll(query: $query) {
    ${MenuItemData}
  }
}
`;

export const GET_TREE_MENU_ITEMS = gql`
  query {
    MenuItem_getTree {
      id
      name
      description
      level
      icon
      url
      children {
        id
        name
        description
        level
        icon
        url
        children {
          id
          name
          description
          icon
          level
          url
        }
      }
    }
  }
`;

export const CREATE_MENU_ITEM = gql`
mutation MenuItem_create($payload: CreateMenuItemInput!) {
  MenuItem_create(createMenuItemInput: $payload) {
    ${MenuItemData}
  }
}
`;

export const EDIT_MENU_ITEM = gql`
mutation MenuItem_update($payload: UpdateMenuItemInput!) {
  MenuItem_update(updateMenuItemInput: $payload) {
    ${MenuItemData}
  }
}
`;

export const DELETE_MENU_ITEM = gql`
  mutation MenuItem_delete($id: String!) {
    MenuItem_delete(id: $id)
  }
`;

export const FIND_MENU_ITEM = gql`
  query MenuItem_findById($id: String!) {
    MenuItem_findById(id: $id) {
      ${MenuItemData}
    }
  }
`;
