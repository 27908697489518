import { Table, TableProps, Typography } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import React, { useState } from 'react';
import './styles.css';
export declare type Key = React.Key;

interface renderSelection {
  type: 'checkbox' | 'radio';
  selections: any[];
  selectedRowKeys: Key[];
  onChange: (selectedRowKeys: Key[]) => void;
}

type Props = Omit<TableProps<any>, 'sticky' | 'scroll'> & {
  tableName?: string;
  dataExpand?: string[];
  isBorder?: boolean;
  scrollY?: string | number;
  scrollX?: string | number;
  isRenderSelection?: boolean;
  isPagination?: boolean;
  onChange?: TableProps<any>['onChange'];
};

const CoreTable = (props: Props) => {
  const {
    columns,
    dataSource,
    dataExpand,
    expandable,
    rowSelection,
    isBorder = true,
    scrollY = '600px',
    scrollX = 'max-content',
    isRenderSelection = false,
    pagination,
    isPagination = false,
    onChange = undefined,
    ...restProps
  } = props;

  const [selectedRows, setSelectedRows] = useState([]);

  // Help Render RowSelection
  const isSelection: renderSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedRows,
    onChange: (key: any) => {
      setSelectedRows(key);
    },
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE, Table.SELECTION_INVERT],
  };

  // Help ExpandedRow Fast
  const expand = () => {
    return {
      expandedRowRender: (record: any) => {
        if (dataExpand) {
          return dataExpand.map((item, index) => <p key={index}>{record[item]}</p>);
        }
      },
    };
  };

  const formatData = () => {
    if (Array.isArray(dataSource) && dataSource.length > 0) {
      return dataSource.map((item) => {
        if (item.key) {
          return item;
        }
        return {
          ...item,
          key: item.id,
        };
      });
    }
    return [];
  };

  return (
    <Table
      columns={columns}
      dataSource={formatData()}
      bordered={isBorder}
      rowSelection={isRenderSelection ? isSelection : rowSelection}
      expandable={expandable ? expandable : dataExpand ? expand() : undefined}
      // rowClassName='bg-blue-400'
      pagination={isPagination ? pagination : false}
      onChange={onChange}
      {...restProps}
    />
  );
};

export default CoreTable;
