import { getErrorMessage } from '@/components/hooks/useGetErrorMessage';
import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { useCoreContext } from '@/core';
import { DistrictPayloadForm, PropsDistrictForm } from '@/domains/district/ports/payloads';
import { CreateDistrictUsecase } from '@/domains/district/usecases';
import { UpdateDistrictUsecase } from '@/domains/district/usecases/update.usecase';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { DistrictHttpRepository } from '../adapters/repositories';
import { validateDistrict } from '../validations';

const useDistrictForm = (props: PropsDistrictForm) => {
  const { detailDistrict } = props;
  const { actionGetListDistrict, getValues, reset } = useCoreContext();
  const { handleClose } = useHandleActionItem();
  const [currentQueryParameters] = useSearchParams();
  const showId = currentQueryParameters.get('id');
  const createDistrictUsecase = new CreateDistrictUsecase(new DistrictHttpRepository());
  const updateDistrictUsecase = new UpdateDistrictUsecase(new DistrictHttpRepository());

  const { schemaDistrict } = validateDistrict(props);

  const initialValue = {
    id: '',
    code: '',
    city: '',
    name: '',
    isActive: true,
  };

  const methodForm = useForm({
    mode: 'onTouched',
    defaultValues: {
      id: detailDistrict.id ?? '',
      code: detailDistrict.code ?? '',
      city: detailDistrict.city ?? '',
      name: detailDistrict.name ?? '',
      isActive: detailDistrict.isActive ?? true,
    },
    resolver: yupResolver(schemaDistrict),
  });

  const onSubmit = methodForm.handleSubmit(async (data: DistrictPayloadForm) => {
    try {
      if (!showId) {
        delete data.id;
        await createDistrictUsecase.run(data);
        notification.success({
          message: 'You already have successfully created city',
          placement: 'bottomRight',
        });
      } else {
        delete data.code;
        await updateDistrictUsecase.run(data);
        notification.success({
          message: 'You already have successfully updated city',
          placement: 'bottomRight',
        });
      }
      handleClose();
      methodForm.reset({
        ...initialValue,
      });

      reset();
      actionGetListDistrict(getValues());
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getErrorMessage(error),
        placement: 'bottomRight',
      });
    }
  });

  return {
    methodForm,
    onSubmit,
    initialValue,
  };
};

export default useDistrictForm;
