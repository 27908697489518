import { CityEntity } from '../entities';
import { CityRepository } from '../ports/repositories';

export class FindCityUseCase {
  constructor(private readonly repository: CityRepository) {}

  async find(id: string): Promise<CityEntity> {
    const data = this.repository.getDetailCity(id);
    return data;
  }
}
