import CoreTable from '@/components/Table';
import { useCoreContext } from '@/core';
import React, { useEffect } from 'react';
import { RolesTableColumns } from '../../components/RolesTableColumns';
import { get } from 'lodash';
import { Typography } from 'antd';
import { CorePagination } from '@/components/Pagination';

export const RolesTable = () => {
  const { dataList, loadingRoles, setValue, getValues, reset } = useCoreContext();
  const tableColumns = RolesTableColumns();

  return (
    <>
      <CoreTable
        columns={tableColumns}
        dataSource={get(dataList, 'data', [])}
        tableName='Role'
        loading={loadingRoles}
      />
      <div className='flex mt-4 justify-between'>
        <Typography.Title level={3}>
          Total: {get(dataList, 'count') > 999 ? '>= 1000' : get(dataList, 'count')}
        </Typography.Title>
        <CorePagination
          total={get(dataList, 'count') ?? 0}
          defaultPageSize={20}
          showSizeChanger
          pageSizeOptions={['5', '10', '20']}
          onChange={(page, pageSize) => {
            setValue('page', page);
            setValue('limit', pageSize);
          }}
          current={getValues('page')}
        />
      </div>
    </>
  );
};
