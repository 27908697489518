export const CenterData = `
  id
  shortName
  name
  hotLine
  email
  address {
    countryCode
    cityCode
    districtCode
    wardCode
    country {
      id
      code
      name
    }
    city {
      id
      code
      name
    }
    district {
      id
      code
      name
    }
    ward {
      id
      code
      name
    }
    address
  }
  coordinate
  isActive
  businessUnits
`;
