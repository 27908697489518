import React, { useEffect, useState } from 'react';
import { AppData } from './PageContext';
import { getPermission } from '@/modules/accounts/viewmodels/getPermission';
import { authenticate, getCustomTokenFromSSOServer } from '../auth';
import { AllUsersUseCase } from '@/domain/use-cases/users';
import { UsersRepoIml } from '@/modules/users/adapters/repositories';
import { getUserByFirebaseId } from '@/modules/users/viewmodels/findFirebaseId';
import { UsersEntity } from '@/domain/entities/users';
import { SocketManager } from '@mx/socket/dist/socket';
import { useSSOAuthentication } from '../hooks';

interface Data {
  permission: string[];
  userData: UsersEntity;
  socketManager: SocketManager | undefined;
}

export const AppProvider = (props: any) => {
  const getUser = new AllUsersUseCase(new UsersRepoIml());
  const { actionGetPermission, permissionData } = getPermission();
  const { userData, actionGetUserByFirebaseId } = getUserByFirebaseId();

  const { socketManager } = useSSOAuthentication(true);
  const findUser = async () => {
    const customToken = await getCustomTokenFromSSOServer();
    const rs = await authenticate(customToken);
    const { userId } = rs;
    if (userId) {
      actionGetUserByFirebaseId(userId);
    }
  };

  useEffect(() => {
    actionGetPermission();
    findUser();
  }, []);

  const data: Data = {
    permission: permissionData,
    userData,
    socketManager,
  };

  return <AppData {...data}>{props.children}</AppData>;
};
