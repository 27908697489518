import { getErrorMessage } from '@/components/hooks/useGetErrorMessage';
import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { useCoreContext } from '@/core';
import { CountriesEntity } from '@/domains/countries/entities';
import { CountriesPayloadForm } from '@/domains/countries/ports/payloads';
import { CreateCountriesUsecase, UpdateCountriesUsecase } from '@/domains/countries/usecases';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { CountriesHttpRepository } from '../adapters/repositories';
import { validateCountry } from '../validations';

export interface PropsCountryForm {
  detailCountry: CountriesEntity;
}

const useCenterForm = (props: PropsCountryForm) => {
  const { detailCountry } = props;

  const { actionGetListCountry, getValues, reset } = useCoreContext();
  const { handleClose } = useHandleActionItem();
  const [currentQueryParameters] = useSearchParams();
  const showId = currentQueryParameters.get('id');
  const createCountriesUsecase = new CreateCountriesUsecase(new CountriesHttpRepository());
  const updateCountriesUsecase = new UpdateCountriesUsecase(new CountriesHttpRepository());

  const { schemaCountry } = validateCountry(props);

  const initialValue = {
    id: '',
    code: '',
    dialCode: '',
    name: '',
    isActive: true,
  };

  const methodForm = useForm({
    mode: 'onTouched',
    defaultValues: {
      id: detailCountry.id ?? '',
      code: detailCountry.code ?? '',
      dialCode: detailCountry.dialCode ?? '',
      name: detailCountry.name ?? '',
      isActive: detailCountry.isActive ?? true,
    },
    resolver: yupResolver(schemaCountry),
  });

  const onSubmit = methodForm.handleSubmit(async (data: CountriesPayloadForm) => {
    try {
      if (!showId) {
        delete data.id;
        await createCountriesUsecase.run(data);
        notification.success({
          message: 'You already have successfully created country',
          placement: 'bottomRight',
        });
      } else {
        delete data.code;
        delete data.dialCode;
        await updateCountriesUsecase.run(data);
        notification.success({
          message: 'You already have successfully updated country',
          placement: 'bottomRight',
        });
      }
      handleClose();
      methodForm.reset({
        ...initialValue,
      });

      reset();
      actionGetListCountry(getValues());
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getErrorMessage(error),
        placement: 'bottomRight',
      });
    }
  });

  return {
    methodForm,
    onSubmit,
    initialValue,
  };
};

export default useCenterForm;
