import { Collapse, Divider } from 'antd';
import React, { useContext } from 'react';
import { RolesCheckBoxForm } from './RolesCheckBoxForm';
import { RolesContext } from './RolesForm';
import useGetAllPermission from '../../hooks/useGetAllPermission';

const { Panel } = Collapse;
interface Props {
  keyData: string;
} // eslint-disable-next-line react/display-name
export const RolesPanel = React.memo<Props>((props: Props) => {
  const { keyData } = props;
  const { selectedPermissions, data, nameApp, listItemChecked, setListItemChecked } =
    useContext(RolesContext);
  const {
    groupDisplayName,
    allSelected,
    permissionArrays,
    setClicked,
    toggleAll,
    isSelected,
    toggle,
  } = useGetAllPermission(
    data,
    keyData,
    nameApp,
    selectedPermissions,
    listItemChecked,
    setListItemChecked,
  );

  return (
    <Collapse ghost expandIconPosition='end' collapsible='icon' className='items-center'>
      <Panel
        key={keyData}
        header={
          <div className='flex justify-between items-center'>
            <h3>{groupDisplayName}</h3>
            <Divider style={{ minWidth: '0px', flex: 1, margin: '0px 14px' }} />
            <input
              className='mr-2 h-4 w-4'
              type='checkbox'
              checked={allSelected}
              onClick={() => {
                setClicked(true);
                toggleAll();
              }}
            />
            Check all
          </div>
        }
      >
        <RolesCheckBoxForm
          list={permissionArrays}
          nameApp={nameApp}
          keyData={keyData}
          isSelected={isSelected}
          toggle={toggle}
        />
      </Panel>
    </Collapse>
  );
});
