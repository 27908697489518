import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import {
  CHECK_EXISTED_WARD,
  CREATE_WARD,
  GET_ALL_WARD,
  GET_DETAIL_WARD,
  GET_LIST_WARD,
  UPDATE_WARD,
} from '../../graphql';
import { WardRepository } from '@/domains/wards/ports/repositories';
import { QueryWardPayload, WardPayloadForm } from '@/domains/wards/ports/payloads';
import { ListWardPaging, WardEntity } from '@/domains/wards/entities';

export class WardHttpRepository implements WardRepository {
  async createWard(payload: WardPayloadForm): Promise<WardEntity> {
    const response: WardEntity = await HttpClientAdapter.mutate<WardEntity>({
      mutation: CREATE_WARD,
      variables: { payload: { ...payload } },
    });
    return response;
  }

  async updateWard(payload: WardPayloadForm): Promise<WardEntity> {
    const response: WardEntity = await HttpClientAdapter.mutate<WardEntity>({
      mutation: UPDATE_WARD,
      variables: { payload: { ...payload } },
    });
    return response;
  }

  async getListWard(payload?: QueryWardPayload): Promise<ListWardPaging> {
    const response: ListWardPaging = await HttpClientAdapter.query<ListWardPaging>({
      query: GET_LIST_WARD,
      dataKey: 'Wards_getList',
      variables: {
        query: { limit: 10, page: 1, sortBy: ['lastModifiedAt|desc', '_id|desc'], ...payload },
      },
    });
    return response;
  }

  async getDetailWard(id: string): Promise<WardEntity> {
    const response: WardEntity = await HttpClientAdapter.query<WardEntity>({
      query: GET_DETAIL_WARD,
      dataKey: 'Ward_getById',
      variables: { id },
    });
    return response;
  }

  async getAllWard(payload?: QueryWardPayload | undefined): Promise<WardEntity[]> {
    const response: WardEntity[] = await HttpClientAdapter.query<WardEntity[]>({
      query: GET_ALL_WARD,
      dataKey: 'Ward_getAll',
      variables: { payload: { ...payload } },
    });
    return response;
  }

  async checkExistWardByCode(code: string, aborterRef: any): Promise<WardEntity | null> {
    try {
      const response: WardEntity = await HttpClientAdapter.query<WardEntity>({
        query: CHECK_EXISTED_WARD,
        variables: { payload: { code } },
        fetchPolicy: 'network-only',
        context: {
          fetchOptions: {
            signal: aborterRef.current.signal,
          },
        },
        dataKey: 'Ward_getByCriteria',
      });
      return response;
    } catch (error) {
      return null;
    }
  }
}
