import { gql } from '@apollo/client';
import { EmployeeData } from './employee';
import { ImportEmployeeData, ImportEmployeeDetailData } from './import-employee';

export const GET_EMPLOYEE_LIST = gql`
query Employee_getList($query:QueryEmployeeInput){
    Employee_find(query:$query){
        data{
            ${EmployeeData}
        }
        count
        page
        pages
        limit
    }
}
`;

export const IMPORT_EMPLOYEE = gql`
  mutation ImportEmployee_import($input: ImportEmployeeInput) {
    ImportEmployee_import(input: $input) {
      id
    }
  }
`;

export const FIND_BY_ID_EMPLOYEE = gql`
query Employee_findById($id: String!){
    Employee_findById(id: $id){
        ${EmployeeData}
    }
}
`;

export const UPDATE_EMPLOYEE = gql`
  mutation Employee_update($input: UpdateEmployeeInput!) {
    Employee_update(input: $input) {
      ${EmployeeData}
    }
  }`;

export const FIND_WORK_SHIFT = gql`
  query WorkShift_find($query: FindWorkShiftInput) {
    WorkShift_find(query: $query) {
      id
      name
    }
  }
`;

export const FIND_PAGINATE_IMPORT_EMPLOYEE = gql`
query ImportEmployee_getList($input: ImportTaskListInput ) {
  ImportEmployee_getList(input: $input) {
   ${ImportEmployeeData}
  }
}
`;
export const FIND_DETAIL_IMPORT_EMPLOYEE = gql`
query ImportEmployee_findDetail($id: String! ) {
  ImportEmployee_findDetail(id: $id) {
   ${ImportEmployeeDetailData}
  }
}
`;
