import { CoreInput } from '@/components/Form/InputAntForm';
import CoreSelect from '@/components/Form/SelectAntForm';
import { CoreSwitch } from '@/components/Form/Switch';
import { useCoreContext } from '@/core';
import { PropsDistrictForm } from '@/domains/district/ports/payloads';
import { Button, Typography } from 'antd';
import { debounce, get } from 'lodash';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import useViewModel from '../../viewmodels/district-form.viewmodel';

export const DistrictForm = (props: PropsDistrictForm) => {
  const { openForm, detailDistrict } = props;
  const { cityData, searchCity } = useCoreContext();
  const [currentQueryParameters] = useSearchParams();
  const showId = currentQueryParameters.get('id');
  const { methodForm, initialValue, onSubmit } = useViewModel(props);
  const {
    control,
    reset,
    formState: { isSubmitting, isDirty },
  } = methodForm;

  useEffect(() => {
    if (!showId) {
      reset(initialValue);
    }
  }, [openForm]);

  return (
    <FormProvider {...methodForm}>
      <form onSubmit={onSubmit}>
        <div className='w-full flex'>
          <div className='px-3 mb-6'>
            <Typography.Title level={4}>
              {showId ? get(detailDistrict, 'name') : 'Create'}
            </Typography.Title>
          </div>
          <div className='ml-auto'>
            <CoreSwitch
              control={control}
              name='isActive'
              className='px-3 mt-2'
              formatValue={true}
            />
          </div>
        </div>
        <div className='w-full flex flex-wrap'>
          <CoreInput
            control={control}
            name='code'
            label='Code'
            className='w-1/2 mb-5 px-3'
            disabled={showId !== null}
          />
          <CoreInput control={control} name='name' label='Name' className='w-1/2 mb-5 px-3' />
          <CoreSelect
            control={control}
            optionsProps={get(cityData, 'data', [])}
            showSearch
            onSearch={debounce((search: string) => searchCity(search), 500)}
            filterOption={false}
            name='city'
            label='City'
            displayField='name'
            valueField='id'
            className='w-1/2 mb-5 px-3'
          />
        </div>
        <div className='text-right px-3 mt-3'>
          <Button type='primary' htmlType='submit' loading={isSubmitting} disabled={!isDirty}>
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
