// Generate code from clean architecture template

import { ImportEmployeeEntity } from '../entities';
import { ImportEmployeeRepository } from '../ports/repositories';

export class FindDetailImportEmployeeUsecase {
  constructor(private readonly repo: ImportEmployeeRepository) {}

  async run(id: string): Promise<ImportEmployeeEntity> {
    return await this.repo.findDetail(id);
  }
}
